import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'kope-table',
    templateUrl: './table.component.html'
})
export class TableComponent implements OnInit {

    @Input() lb: any;
    @Input() type: string ='table';
    constructor() { }

    ngOnInit() {}

}
