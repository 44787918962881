import { PrintLayoutDS } from './print-layout.ds';
import { PrintLayoutEH } from './print-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';
import { PrintDS, 
  TitleDS, 
  ListDS, 
  AnagraficaTitleDS,
  AnagraficaEmployeesDS,
  AnagraficaMetadataDS,
  AnagraficaRevenueDS,
  AnagraficaSocialDS,
  ChartsCol2DataDS,
  ChartsCol2TitleDS,
  ChartsCol2SectionTitleDS,
  NestedListDS, 
  NewsDS,
  TableDS, 
  CgsDS, 
  EventsDS,
  ScoreDS,
  CovidTitleDS,
  CovidAnalysisDS,
  CovidScoreDS,
  ChartsCol2WidgetsDS,
  ShareholdersChartDS,
  ChartsCol3SectionTitleDS,
  ChartsCol3TitleDS,
  ShareholdersDS,
  ChartsCol3DataDS,
  ScoreWidgetsDS,
  ScoreSectionTitleDS,
  ScoreTitleDS,
  CgsWidgetDS,
  ExtendedCgsWidgetDS,
  ExtendedCgsDS,
  ExtendedCgsSubscoreDS,
  DashboardTitleDS,
  DashboardScoreDS,
  DashboardSectorDS,
  DashboardTrendDS,
  DashboardMetaDS,
  AnagraficaLocationsDS} from '../../data-sources';

export const PrintLayoutConfig = {
  layoutId: 'print-layout',
  widgets: [
    {
      id: 'print',
      dataSource: PrintDS
    },
    {
      id: 'title',
      dataSource: TitleDS
    },
    {
      id: 'list',
      dataSource: ListDS
    },
    {
      id: 'real_estates',
      dataSource: TableDS,
      options: { key: 'real_estates' }
    },
    {
      id: 'table',
      dataSource: TableDS,
      options: { key: 'table' }
    },
    {
      id: 'people',
      dataSource: TableDS,
      options: { key: 'people' }
    },
    {
      id: 'beneficial_owner',
      dataSource: TableDS,
      options: { key: 'beneficial_owner' }
    },
    {
      id: 'anagrafica-title',
      dataSource: AnagraficaTitleDS
    },
    {
      id: 'anagrafica-employees',
      dataSource: AnagraficaEmployeesDS
    },
    {
      id: 'anagrafica-revenue',
      dataSource: AnagraficaRevenueDS
    },
    {
      id: 'anagrafica-metadata',
      dataSource:AnagraficaMetadataDS
    },
    {
      id: 'anagrafica-social',
      dataSource: AnagraficaSocialDS
    },
    {
      id: 'anagrafica-locations',
      dataSource: AnagraficaLocationsDS
    },
    {
      id: 'charts-col-2-section-title',
      dataSource: ChartsCol2SectionTitleDS,
    },
    {
      id: 'charts-col-2-title',
      dataSource: ChartsCol2TitleDS,
    },
    {
      id: 'charts-col-2-widgets',
      dataSource: ChartsCol2WidgetsDS
    },
    {
      id: 'charts-col-2-data',
      dataSource: ChartsCol2DataDS
    },
    {
      id: 'shareholders-chart',
      dataSource: ShareholdersChartDS
    },
    {
      id: 'shareholders',
      dataSource: ShareholdersDS
    },
    {
      id: 'nested-list',
      dataSource:  NestedListDS
    },
    {
      id: 'news',
      dataSource: NewsDS
    },
    {
      id: 'charts-col-3-section-title',
      dataSource: ChartsCol3SectionTitleDS,
    },
    {
      id: 'charts-col-3-title',
      dataSource: ChartsCol3TitleDS,
    },
    {
      id: 'charts-col-3-data',
      dataSource: ChartsCol3DataDS
    },
    {
      id: 'cgs',
      dataSource: CgsDS
    },
    {
      id: 'cgs-widget',
      dataSource: CgsWidgetDS
    },
    {
      id: 'extended-cgs',
      dataSource: ExtendedCgsDS
    },
    {
      id: 'extended-cgs-widget',
      dataSource: ExtendedCgsWidgetDS
    },
    {
      id: 'extended-cgs-subscore',
      dataSource: ExtendedCgsSubscoreDS
    },
    {
      id: 'kope-events',
      dataSource: EventsDS
    },
    {
      id: 'score-section-title',
      dataSource: ScoreSectionTitleDS
    },
    {
      id: 'score-title',
      dataSource: ScoreTitleDS
    },
    {
      id: 'score',
      dataSource: ScoreDS
    },
    {
      id: 'score-widgets',
      dataSource: ScoreWidgetsDS
    },
    {
      id: 'dashboard-title',
      dataSource: DashboardTitleDS,
    },
    {
      id: 'dashboard-score',
      dataSource: DashboardScoreDS,
    },
    {
      id: 'dashboard-sector',
      dataSource: DashboardSectorDS,
    },
    {
      id: 'dashboard-trend',
      dataSource: DashboardTrendDS,
    },
    {
      id: 'dashboard-metadata',
      dataSource: DashboardMetaDS,
    },
    {
      id: 'covid-title',
      dataSource: CovidTitleDS
    },
    {
      id: 'covid-analysis',
      dataSource: CovidAnalysisDS
    },
    {
      id: 'covid-score',
      dataSource: CovidScoreDS
    }
  ],
  layoutDS: PrintLayoutDS,
  layoutEH: PrintLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {

  }
};
