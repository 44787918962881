import { Utils } from 'src/app/common/utils';
export const createDataWidget = (data, subtext) => {
    if (!data || !data.values || data.values.length === 0) {
      return;
    }
    let increment = 0;
    let lastValue = data.last_value;
    let showSubtitle = true;

    const l = data.values.length;
    const last = l > 0 ? data.values[l - 1].value : null;
    const beforelast = l > 1 ? data.values[l - 2].value : 1;
    increment = Math.round((last - beforelast) * 100 / beforelast);

    if (!last) { return; }

    if (!isFinite(increment)) { showSubtitle = false; }
    return {
      // icon: 'n7-icon-earth',
      text: lastValue,
      subtitle: showSubtitle ? {
        text: subtext,
        icon: increment > 0 ? 'n7-icon-caret-up' : 'n7-icon-caret-down',
        value: increment + '%',
        payload: 'view percent tooltip '
      } : null,
      payload: 'view earth tooltip',
      classes: increment > 0 ? 'is-positive' : 'is-negative'
    };
  }

  export const createChart = (data, chartName) => {
    if (!data || !data.values || data.values.length === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }
    const values = data.values.map(x => x.value);
    const categories = data.values.map(x => x.year);
    const chartOption = {
      series: [{
        name: data.title,
        data: values
      }],
      colors: ['#1F5871'],
      chart: {
        id: chartName,
        fontFamily: 'Open Sans',
        height: 100,
        animations: {
          enabled: false
        },
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      markers: {
        size: [4],
        strokeWidth: 2,
        hover: {
          size: [6]
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.71,
          opacityTo: 0.01,
        }
      },
      xaxis: {
        categories: categories,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: (min) => min - (Math.abs(min) / Utils.minMaxAxis),
        max: (max) => max + (Math.abs(max) / Utils.minMaxAxis),
        tickAmount: 5,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: chartName,
      libOptions: chartOption,
      title: {
        main: {
          text: data.title,
          classes: 'bold'
        }
      },
      payload: { loaded: true }
    };
  }