import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
  N7BoilerplateCommonModule,
  N7BoilerplateDataVizModule,
  JsonConfigService
} from '@n7-frontend/boilerplate';
import globalConfig from './config/global';
import layoutsConfig from './config/layouts';
import { AppComponent } from './app.component';
import { DvComponentsLibModule } from '@n7-frontend/components';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { PrintLayoutComponent } from './layouts/print-layout/print-layout';
import { DcSectionComponent } from './components/dc-section/dc-section.component';
import { ChartComponent } from './components/chart/chart.component';
import { PrintModule } from './modules/print/print.module';
import { TitleComponent } from './sections/title/kope-title.component';
import { ListComponent } from './sections/list/list.component';
import { NestedListComponent } from './sections/nested-list/nested-list.component';
import { AnagraficaComponent } from './sections/anagrafica/anagrafica.component';
import { TableComponent } from './sections/table/table.component';

import { ShareholdersComponent } from './sections/shareholders/shareholders.component';
import { NewsComponent } from './sections/news/news.component';
import { CgsComponent } from './sections/cgs/cgs.component';
import { EventsComponent } from './sections/events/events.component';
import { ScoreComponent } from './sections/score/score.component';
import { CovidComponent } from './sections/covid/covid.component';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { ChartCol2Component } from './sections/charts-col-2/charts-col-2.component';
import { ChartsCol3Component } from './sections/charts-col-3/charts-col-3.component';
import { FeverChartComponent } from './components/fever-chart/fever-chart';
import { ExtendedCgsComponent } from './sections/extended-cgs/extended-cgs.component';

const JSON_PATH = './assets/app-config.json';

@NgModule({
  declarations: [
    AppComponent,
    ChartComponent,
    DcSectionComponent,
    PrintLayoutComponent,
    TitleComponent,
    ListComponent,
    NestedListComponent,
    AnagraficaComponent,
    TableComponent,
    ChartCol2Component,
    ShareholdersComponent,
    NestedListComponent,
    NewsComponent,
    ChartsCol3Component,
    CgsComponent,
    ExtendedCgsComponent,
    EventsComponent,
    ScoreComponent,
    DashboardComponent,
    CovidComponent,
    FeverChartComponent
  ],
  imports: [
    BrowserModule,
    PrintModule,
    CommonModule,
    RouterModule.forRoot(
      APP_ROUTES, {onSameUrlNavigation: 'reload'}
    ),
    N7BoilerplateCommonModule.forRoot({
      global: globalConfig,
      layouts: layoutsConfig
    }),
    N7BoilerplateDataVizModule,
    DvComponentsLibModule
  ],
  providers: [
  {
    provide: APP_INITIALIZER,
    useFactory: (jsonConfigService: JsonConfigService) => () => jsonConfigService.load(JSON_PATH),
    deps: [JsonConfigService],
    multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
