import { DataSource } from '@n7-frontend/core';
export class DashboardMetaDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'dashboard')) {
      return;
    }
    const dashboardData = data.find(element => element.id === 'dashboard');
    const metatada = this.buildMetadata(dashboardData);
    return metatada;
  }
  private buildMetadata = (data)=>{
    if(!data || !data.metadata){
        return;
    }
    let metadata = data.metadata;
    const color = this.getColorFromClass(data);
    if(color){
      metadata = metadata.map(item => this.addColorToValutazioneComplessiva(item,color))
    }
    return metadata;
  }
  private getColorFromClass = (data)=>{
    if(!data.sector || !data.sector.current_class || !data.sector.items){
      return;
    }
    const currentClass = data.sector.items.find(item => item.label === data.sector.current_class);
    const color = currentClass && currentClass.color;
    return color;
  }
  private addColorToValutazioneComplessiva(item, color){
    if(item.label === 'Valutazione complessiva'){
      item.color = color;
    }
    return item;
  }
}
