import { DataSource } from '@n7-frontend/core';
export class ListDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'list')){
      return;
    }

    const listData = data.find(element=> element.id === 'list');
    const list = {
      title: {
        main: {
          text: listData.title,
          classes: 'bold'
        }
      },
      subtitle: listData.text,
      footer_text: listData.footer_text,
      items: listData.items
    };
    return list;
  }
}

