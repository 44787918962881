import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'score',
    templateUrl: './score.component.html'
})
export class ScoreComponent {

    @Input() lb: any;
    constructor() { }
}
