import { Component, OnInit, Input } from '@angular/core';
@Component({
    selector: 'cgs',
    templateUrl: './cgs.component.html'
})
export class CgsComponent {
    @Input() lb: any;

    constructor() { }
}
