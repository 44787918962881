import { DataSource } from '@n7-frontend/core';
import { element } from 'protractor';
import { ScoreEnums } from 'src/app/common/score-enums';
export class  ScoreTitleDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'score')) {
            return;
        }
        const indexChartData = data.find(element=> element.id === 'score');
        const indexChartTitle = indexChartData.items.map(this.createTitle);
        return indexChartTitle;
    }
    private createTitle(data){
        const scoreEnums = new ScoreEnums()
        if(!data || !data.score || !scoreEnums.getScoreNames().includes(data.score)){
            return;
        }
        return {
            title: {
                main: {
                  text:  scoreEnums.getLabelByScoreName(data.score),
                  classes: 'bold'
                },
                secondary: {
                    text: data.last_update ? `Dato aggiornato al ${data.last_update}`: undefined
                }
              }
        };
    }

}
