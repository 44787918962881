import { DataSource } from '@n7-frontend/core';
import { Utils } from 'src/app/common/utils';
export class AnagraficaTitleDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'anagrafica')) {
      return;
    }
    const anagraficaData = data.find(element => element.id === 'anagrafica');
    const anagrafica = {
      main: {
        ...Utils.createInnerTitle(anagraficaData.title),
        header: this.createHeader(anagraficaData),
        description: anagraficaData.description,
        oggetto_sociale: anagraficaData.oggetto_sociale
      },
    };
    return anagrafica;
  }
  private createHeader = (anagraficaData) => {
    if (!anagraficaData.header) {
      return;
    }
    if (!anagraficaData.header.title) {
      anagraficaData.header.title = this.getRagioneSociale(anagraficaData);
    }
    return anagraficaData.header;
  }
  private getRagioneSociale = (anagraficaData) => {
    if (!anagraficaData || !anagraficaData.metadata || !anagraficaData.metadata.length) {
      return;
    }
    const ragioneSocialeItem = anagraficaData.metadata.find(element => element.label == "Ragione Sociale");
    return ragioneSocialeItem.value;
  }
}
