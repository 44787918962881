import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageBreakDirective } from './directives/page-break.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PageBreakDirective
  ],
  exports: [PageBreakDirective]
})
export class PrintModule { }