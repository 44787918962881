import { Component, OnInit, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

    @Input() lb: any;
    images = [];
    scoreElements = [
        {
            "title": " PN/Passivo",
            "description": "Confronta l’apporto degli azionisti con quello degli altri portatori di interesse (finanziatori, fornitori, dipendenti, Stato,..). Aziende strutturalmente indebitate – sia con capitale di finanziamento che di funzionamento – presentano valori ridotti."
        },
        {
            "title": "Current ratio",
            "description": "La solvibilità aziendale viene esaminata valutando la correlazione temporale tra attività e passività aventi stessa scadenza. Normalmente si considera equilibrata la situazione in cui le attività sono superiori alle passività."
        },
        {
            "title": "CF/Attivo",
            "description": "Capacità dell’impresa di produrre flussi di cassa in rapporto alle attività investite. Il cash flow viene calcolato rettificando il risultato d’esercizio dai valori non monetari (ammortamenti, accantonamenti,…) . Valori elevati sono indice di elevata redditività."
        },
        {
            "title": "OF/Ricavi",
            "description": "Valuta il peso degli oneri finanziari sui ricavi dell’esercizio. Valori elevati, anche in confronto con i valori del settore, sono indice di possibili difficoltà nel sostenere l’indebitamento finanziario."
        }
    ];
    constructor(private chartService: ChartService) { }
    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['print'].ds.out$.subscribe(data => {
            if (data) {
                setTimeout(() => {
                        this.chartService.convertToImage([
                        'dashboard-score-chart',
                        'dashboard-trend-chart-0',
                        'dashboard-trend-chart-1',
                        'dashboard-trend-chart-2',
                        'dashboard-trend-chart-3',
                        'dashboard-sector-chart'
                    ], this.images).then(() => {
                            this.chartService.readyForPrint();
                        });
                }, this.chartService.waitTime);
            }
        });
    }
}
