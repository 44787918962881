import { DataSource } from '@n7-frontend/core';
export class TitleDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element => element.id === 'title')){
      return;
    }
    const titleData = data.find(element => element.id === 'title');
    const title = {
      title: {
        main: {
          text:  titleData.title,
          classes: 'bold'
        }
      },
      text: titleData.text
    };
    return title;
  }
}
