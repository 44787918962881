import { DataSource } from '@n7-frontend/core';
export class AnagraficaMetadataDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'anagrafica')){
      return;
    }
    const anagraficaData = data.find(element => element.id === 'anagrafica');
    const anagrafica = {
      group: [{
        items: anagraficaData.metadata
      }]
    };
    return anagrafica;
  }
}
