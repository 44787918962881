import { LayoutDataSource } from '@n7-frontend/core';

export class PrintLayoutDS extends LayoutDataSource{

  onInit(payload) {
  }

  public updateWidgets = (data) => {
    this.all().update(data);
  }

}
