import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'events',
    templateUrl: './events.component.html'
})
export class EventsComponent implements OnInit {

    @Input() lb: any;

    constructor() { }

    ngOnInit() {}

}
