import { DataSource } from '@n7-frontend/core';
export class CgsDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'cgs')) {
      return;
    }

    const dataCgs = data.find(element => element.id === 'cgs');
    return this.createData(dataCgs);
  }

  private createData(dataCgs) {
    const data = {
      title: {
        main: {
          text: dataCgs.title,
          classes: 'bold'
        }
      },
      item: dataCgs.item
    };
    return data;
  }
}
