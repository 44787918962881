import { DataSource } from '@n7-frontend/core';
import { CgsEnums } from 'src/app/common/cgs-enums';
export class CgsWidgetDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'cgs')){
      return;
    }
    const cgsData = data.find(element=> element.id === 'cgs');
    const cgsWidget = this.createWidget(cgsData);
    return cgsWidget;
  }
  private createWidget(data) {
    const enums = new CgsEnums();
    return {
      containerId: 'cgs-widget',
      width: 200,
      height: 20,
      value: data.value,
      label: enums.getClassLabel(data.value),
      intervals: enums.getClassIntervals(),
      gutter: 2,
      colors: enums.getClassColors()
    }
  }
  
}
