import { DataSource } from '@n7-frontend/core';
export class TableDS extends DataSource {
  protected transform(data, payload): any {
    if (!data || !data.find(element => element.id === 'table' && element.type === payload.key)) {
      return;
    }
    const tableData = data.find(element =>  element.id === 'table' && element.type === payload.key);
    const table = {
      title: {
        main: {
          text: tableData.title,
          classes: 'bold'
        }
      },
      head: [{ "classes": 'HEADER', "cells": tableData.head.map(this.createHeadCell)}],
      body: tableData.body.map(row => {
        return {
          "classes": 'BODY',
          "meta": "_A",
          "cells": this.createBodyRow(row, tableData.head, payload.key)
        }
      })
    };
    return table;
  }
  private createHeadCell = content => { 
    return { "classes": 'CONTENT', "content": content.value } 
  };
  private createBodyCell = (value, tableType) => {
    if(tableType === 'real_estates' && value === 'plotOfLand'){
       value = 'Appezzamento di terreno';
    }else if(tableType === 'real_estates' && value === 'buildings'){
      value = 'Edifici'
    }
    return {
      "classes": 'CONTENT',
      "content": value,
      "type": 'html'
    };
  }
  private createBodyRow = (rowData, head, tableType)=> {
    return head.map(columnHeader => {
      return this.createBodyCell(rowData[columnHeader.key], tableType);
    })
  }
}