export class ScoreEnums {
    private webCentrality = [
        { class: "low", label: 'Basso', color: "#E9463E" },
        { class: "medium", label: 'Medio', color: "#F0E647" },
        { class: "high", label: 'Alto', color: "#D1DEA6" },
        { class: "top", label: 'Massimo', color: "#2FA037" }
    ];
    private innovation = [
        { class: "low", label: 'Basso', color: "#E9463E" },
        { class: "medium", label: 'Medio', color: "#F0E647" },
        { class: "high", label: 'Alto', color: "#D1DEA6" },
        { class: "top", label: 'Massimo', color: "#2FA037" }
    ];
    private foreignMarket = [
        { class: "minimun", label: 'Minimo', color: "#E9463E" },
        { class: "low", label: 'Basso', color: "#F0E647" },
        { class: "medium", label: 'Medio', color: "#D1DEA6" },
        { class: "high", label: 'Elevato', color: "#ACCB7D" },
        { class: "very high", label: 'Molto Elevato', color: "#9CC455" },
        { class: "certain", label: 'Certo', color: "#2FA037" }
    ];
    public getColorsByScore = (scoreName:string)=>{
        const score = this.getScoreByName(scoreName);
        if(!score){
            return;
        }
        return this.getColors(score);
    }
    public getLabelByScoreName = (scoreName: string) => {
        switch (scoreName) {
            case "web_centrality":
                return "Centralità web";
            case "innovation":
                return "Innovatività";
            case "foreign_market":
                return "Propensione estero";
            default:
                break;
        }
    }
    public getScoreNames = () => {
        return ["web_centrality","innovation","foreign_market"];
    }
    private getScoreByName = (scoreName: string) => {
        switch (scoreName) {
            case "web_centrality":
                return this.webCentrality;
            case "innovation":
                return this.innovation;
            case "foreign_market":
                return this.foreignMarket;
            default:
                break;
        }
    }
    private getColors = (items) =>{
        const colors = [];
        for (const item of items) {
            colors.push(item.color);
        }
        return colors;
    }
    public getLabelByValue = (scoreName: string, value: number) => {
        const scoreValues = this.getScoreByName(scoreName);
        const index = value/(100/scoreValues.length)
        return scoreValues[index] && scoreValues[index].label;
    }
}