export const mock: any = [{
	"title": "Anagrafica",
	"description": "Vibram è una azienda italiana con sede ad Albizzate, in provincia di Varese, che produce suole di gomma per calzature, in particolare scarpe destinate all'impiego in montagna nelle scalate.",
	"header": {
		"logo": "https://s3-eu-west-1.amazonaws.com/static-mastergraph-spaziodati-eu/resources/logos/6496958eca3c502db6c3928c2aad2831540febc5.jpg"
	},
	"metadata": [{
		"label": "Ragione Sociale",
		"value": "\"VIBRAM S.P.A.\""
	}, {
		"label": "Codice Fiscale",
		"value": "00200450120"
	}, {
		"label": "Sul mercato da",
		"value": "1/4/1953"
	}, {
		"label": "Settore prevalente",
		"value": "Ateco (2007) - 22.19.01: <br />Fabbricazione di suole di gomma e altre parti in gomma per calzature"
	}, {
		"label": "Capitale Sociale",
		"value": "1.116.000 €"
	}, {
		"label": "Partita IVA",
		"value": "00200450120"
	}, {
		"label": "Data Chiusura Bilancio",
		"value": "31/12/2019"
	}, {
		"label": "Natura giuridica",
		"value": ["Società Di Capitale"]
	}, {
		"label": "Sito web",
		"value": "http://www.us.vibram.com"
	}, {
		"label": "Stato operatività",
		"value": "Attiva"
	}, {
		"label": "Pec",
		"value": ["vibram-pecamm@certimprese.it"]
	}],
	"social": {
		"facebook": "https://www.facebook.com/VibramIT",
		"twitter": "https://twitter.com/vibram",
		"linkedin": "https://www.linkedin.com/company/vibram-s-p-a-"
	},
	"employees": {
		"last_value": 283,
		"last_update": "1/6/2020",
		"values": [{
			"value": 283,
			"label": 2020
		}, {
			"value": 285,
			"label": 2019
		}, {
			"value": 281,
			"label": 2018
		}, {
			"value": 268,
			"label": 2017
		}, {
			"value": 244,
			"label": 2016
		}]
	},
	"revenue": {
		"last_update": "2019-12-31",
		"last_value": "156.1M",
		"values": [{
			"label": 2019,
			"value": 156063000
		}, {
			"label": 2018,
			"value": 146747000
		}, {
			"label": 2017,
			"value": 138140000
		}, {
			"label": 2016,
			"value": 126652000
		}, {
			"label": 2015,
			"value": 127980000
		}]
	},
	"locations": {
		"number": 4,
		"values": [{
			"label": "Sede principale",
			"address": "Via Cristoforo Colombo, 5, 21041, Albizzate (VA)"
		}, {
			"label": "Negozio",
			"address": "Via Raffaello Sanzio, 6, 20149, Milano (MI)"
		}, {
			"label": "Ufficio Commerciale",
			"address": "Corso Giuseppe Mazzini, 101, 31044, Montebelluna (TV)"
		}]
	},
	"id": "anagrafica",
	"oggetto_sociale": "LA PRODUZIONE E IL COMMERCIO DI ARTICOLI A BASE DI GOMMA E/O MATERIE PLASTICHE, ANCHE ACCOPPIATE AD ALTRI MATERIALI, DI STAMPI E AFFINI, DI ARTICOLI DI ABBIGLIAMENTO E ACCESSORI, NONCHE' LA PRESTAZIONE DI SERVIZI DI DESIGN INERENTI TUTTI I PREDETTI ARTICOLI; L'ACQUISIZIONE DI AZIENDE IN OGNI FORMA E MODALITA', OPERANTI IN SETTORI ANALOGHI O AFFINI AL PROPRIO; L'ASSUNZIONE DI AGENZIE E DI RAPPRESENTANZE IN SETTORI ANALOGHI O AFFINI AL PROPRIO. LA SOCIETA' PUO' - INFINE - COMPIERE, NEL RISPETTO DEI DIVIETI, LIMITI, CONDIZIONI E AUTORIZZAZIONI PREVISTI DALLA LEGGE, TUTTE LE OPERAZIONI COMMERCIALI, MOBILIARI, IMMOBILIARI E FINANZIARIE (NON NEI CONFRONTI DEL PUBBLICO) CHE SARANNO RITENUTE DALL'ORGANO AMMINISTRATIVO NECESSARIE O VANTAGGIOSE PER IL CONSEGUIMENTO DELL'OGGETTO SOCIALE E - A TAL FINE - ASSUMERE, SIA DIRETTAMENTE CHE INDIRETTAMENTE, INTERESSENZE E PARTECIPAZIONI IN SOCIETA', ENTI O IMPRESE, COSTITUITI O COSTITUENDI, AVENTI OGGETTO ANALOGO, AFFINE, COMPLEMENTARE O - COMUNQUE - CONNESSO AL PROPRIO, ATTIVITA' QUESTA DA ESERCITARSI NON NEI CONFRONTI DEL PUBBLICO E IN VIA SECONDARIA (NON PREVALENTE) ED ESCLUSE - COMUNQUE - TUTTE LE ATTIVITA' RISERVATE DI CUI AL DECRETO LEGISLATIVO 24 FEBBRAIO 1998, N. 58; LA SOCIETA' POTRA' - ALTRESI' - RILASCIARE, SEMPRE IN VIA SECONDARIA E NON NEI CONFRONTI DEL PUBBLICO, GARANZIE DI QUALUNQUE NATURA, PERSONALI O REALI, ANCHE A FAVORE DI TERZI E/O PER OBBLIGAZIONI ASSUNTE DA TERZI E/O NELL'INTERESSE DI TERZI. E' INIBITA ALLA SOCIETA' LA RACCOLTA DEL RISPARMIO TRA IL PUBBLICO; SONO TASSATIVAMENTE ESCLUSE DALL'OGGETTO SOCIALE LE ATTIVITA' RISERVATE DI CUI ALLE LEGGI 12/1979, 1966/1939, 1815/1939 E AI DECRETI LEGISLATIVI 385/1993 (ARTICOLO 106) E 58/1998."
}, {
	"title": "Titolare effettivo",
	"head": [{
		"key": "Nome",
		"value": "Nome"
	}],
	"body": [{
		"Nome": "Marco Bramani"
	}, {
		"Nome": "Silvia Antonia Maria Bramani"
	}],
	"id": "table",
	"type": "beneficial_owner"
}, {
	"value": 14,
	"subscores": {
		"C4": "Gravissimi",
		"C3": "Ritardi",
		"C1": "Rischio molto elevato",
		"C5": "Segnali di rischio",
		"C6": "Assenza di segnali di rischio"
	},
	"last_update": "2020/10/10",
	"id": "extended-cgs",
	"title": "CGS"
}, {
	"id": "cgs",
	"value": 100,
	"last_update": "2020-12-07",
	"title": "CGS"
}, {
	"title": "Contatti",
	"items": [{
		"title": "Emails",
		"items": [{
			"key": "Email",
			"value": "vibram@vibram.com"
		}, {
			"key": "Email",
			"value": "info@vibramfivefingers.it"
		}, {
			"key": "Email",
			"value": "sales@vibramfivefingers.it"
		}]
	}, {
		"title": "Telefoni",
		"items": [{
			"key": "telefono",
			"value": "+39 0331 999777 Via Cristoforo Colombo, 5, 21041, Albizzate (VA)"
		}, {
			"key": "telefono",
			"value": "+39 0331 992572 Via Cristoforo Colombo, 5, 21041, Albizzate (VA)"
		}, {
			"key": "telefono",
			"value": "+39 0331 999700 Via Cristoforo Colombo, 5, 21041, Albizzate (VA)"
		}]
	}],
	"id": "nested-list"
}, {
	"score": {
		"title": "Score",
		"value": 1,
		"label": "Massima"
	},
	"analysis": [{
		"title": "Andamento del settore",
		"items": {
			"hard": "Forte contrazione",
			"soft": "Contrazione"
		}
	}, {
		"title": "Tendenza di rischio dell'azienda",
		"items": {
			"hard": "Nessuna variazione rilevante",
			"soft": "Nessuna variazione rilevante"
		}
	},
	{
		"title": "Livello di attenzione dell'azienda",
		"items": {
			"hard": "Mantenere livello di attenzione adeguato",
			"soft": "Mantenere livello di attenzione adeguato"
		},
		"hardScore": "1",
		"lowScore": "7"
	}
	],
	"title": "Analisi impatto Covid-19",
	"id": "covid"
}, {
	"title": "titolo",
	"text": "lorem ipsum",
	"footer_text": "footer",
	"items": [{
		"title": "aaa",
		"data": [{
			"key": "atoka/202011100900/",
			"value": "bbb"
		}]
	}],
	"id": "list"
}, {
	"title": "titolo",
	"head": [{
		"key": "a",
		"value": "Nome"
	}, {
		"key": "b",
		"value": "<span class='align-right' >Importo</span>"
	}, {
		"key": "c",
		"value": ""
	}],
	"body": [{
		"a": "aaa",
		"b": "vvv",
		"c": "ddd"
	}, {
		"a": "aaa",
		"b": "vvv",
		"c": "ddd"
	}, {
		"a": "aaa",
		"b": "vvv",
		"c": "ddd"
	}, {
		"a": "aaa",
		"b": "vvv",
		"c": "ddd"
	}],
	"id": "table",
	"type": "table"
}, {
	"title": "Un test con HTML",
	"text": "<h1>HTML Ipsum Presents</h1><p><strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. <a href=''>Donec non enim</a> in turpis pulvinar facilisis. Ut felis.</p><h2>Header Level 2</h2><ol><li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li><li>Aliquam tincidunt mauris eu risus.</li></ol><blockquote><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.</p></blockquote>",
	"id": "title"
}, {
	"items": [{
		"items": {
			"last_update": "2019-12-31",
			"last_value": "98.6M",
			"values": [{
				"label": 2019,
				"value": 98633000
			}, {
				"label": 2018,
				"value": 93454000
			}, {
				"label": 2017,
				"value": 91308000
			}, {
				"label": 2016,
				"value": 87349000
			}, {
				"label": 2015,
				"value": 80865000
			}]
		},
		"title": "Attivo"
	}, {
		"title": "Ebidta"
	}, {
		"items": {
			"last_update": "2019-12-31",
			"last_value": "25.4M",
			"values": [{
				"label": 2019,
				"value": 25449000
			}, {
				"label": 2018,
				"value": 22237000
			}, {
				"label": 2017,
				"value": 20909000
			}, {
				"label": 2016,
				"value": 15401000
			}, {
				"label": 2015,
				"value": 16962000
			}]
		},
		"title": "Mol"
	}, {
		"items": {
			"last_update": "2019-12-31",
			"last_value": "-52.7M",
			"values": [{
				"label": 2019,
				"value": -52651000
			}, {
				"label": 2018,
				"value": -44326000
			}, {
				"label": 2017,
				"value": -47097000
			}, {
				"label": 2016,
				"value": -42424000
			}, {
				"label": 2015,
				"value": -39282000
			}]
		},
		"title": "Posizione finanziaria netta"
	}, {
		"items": {
			"last_update": "2019-12-31",
			"last_value": "156.1M",
			"values": [{
				"label": 2019,
				"value": 156063000
			}, {
				"label": 2018,
				"value": 146747000
			}, {
				"label": 2017,
				"value": 138140000
			}, {
				"label": 2016,
				"value": 126652000
			}, {
				"label": 2015,
				"value": 127980000
			}]
		},
		"title": "Ricavi"
	}, {
		"items": {
			"last_update": "2019-12-31",
			"last_value": "increasing",
			"values": [{
				"label": 2019,
				"value": "increasing"
			}, {
				"label": 2018,
				"value": "increasing"
			}, {
				"label": 2017,
				"value": "increasing"
			}, {
				"label": 2016,
				"value": "stable"
			}, {
				"label": 2015,
				"value": "increasing"
			}]
		},
		"title": "Trend Ricavi"
	}, {
		"items": {
			"last_update": "2019-12-31",
			"last_value": "1.1M",
			"values": [{
				"label": 2019,
				"value": 1116000
			}, {
				"label": 2018,
				"value": 1116000
			}, {
				"label": 2017,
				"value": 1116000
			}, {
				"label": 2016,
				"value": 1116000
			}, {
				"label": 2015,
				"value": 1116000
			}]
		},
		"title": "Capitale Sociale"
	}, {
		"items": {
			"last_value": 283,
			"last_update": "1/6/2020",
			"values": [{
				"value": 283,
				"label": 2020
			}, {
				"value": 285,
				"label": 2019
			}, {
				"value": 281,
				"label": 2018
			}, {
				"value": 268,
				"label": 2017
			}, {
				"value": 244,
				"label": 2016
			}]
		},
		"title": "Dipendenti"
	}, {
		"items": {
			"last_update": "9/12/2020",
			"last_value": 4
		},
		"title": "Numero di sedi"
	}],
	"id": "charts-col-3"
}, {
	"title": "Immobili",
	"head": [{
		"key": "Tipo",
		"value": "Tipo"
	}, {
		"key": "Indirizzo",
		"value": "Indirizzo"
	}, {
		"key": "Tipo di proprietà",
		"value": "Tipo di proprietà"
	}],
	"body": [{
		"Tipo": "plotOfLand",
		"Indirizzo": "Albizzate (VA)",
		"Tipo di proprietà": "Proprieta'"
	}],
	"id": "table",
	"type": "real_estates"
}, {
	"score": "Dilazionati",
	"last_update": "2020/10/10",
	"id": "events"
}, {
	"items": [{
		"title": "Natale, le migliori idee regalo dal mondo del running",
		"metadata": {
			"source": "gazzetta.it",
			"keywords": ["Abbigliamento", "Natale", "Libertà", "Gel", "Qualità", "Coronavirus", "Fitness", "Giacca", "Forza", "Calzature", "Termoregolazione", "Nuvolosità", "Luce", "Temperatura", "Scarpe", "Francesco Sessa", "Regalo di Natale"],
			"url": "https://recensioni.gazzetta.it/sport/07-12-2020/natale-le-migliori-idee-regalo-dal-mondo-del-running-prima-scelta-5605"
		},
		"excerpt": "Prima scelta: tutte le notizie Salvato nella pagina \"I miei bookmark\" Salvato nella pagina \"I miei bookmark\"Il Natale si avvicina ed è tempo di pensare ai regali: ecco i nostri suggerimenti dal mondo del running, tra capi d'abbigliamento e accessori  Francesco Sessa   07 Dicembre 2020  Il conto alla rovescia verso il Natale è cominciato: ci si avvicina sempre di più all'appuntamento ed è tempo di organizzarsi con i regali. Running, le maglie termiche per tutte le fasce di prezzo Running, le giacche contro il freddo Natale, regali dal mondo del running: i nostri consigli  SCARPA ASICS - La scarpa Gel-Nimbus 23 è il nuovo modello di scarpa da running presentato da Asics , pensato soprattutto per le lunghe distanze. OCCHIALI SMITH - Vediamo ora a una soluzione pensata per proteggere gli occhi e, soprattutto, per garantire un'ottima visibilità in qualunque condizione di luce: gli occhiali Rockus di Smith sono un'idea professionale e di qualità assoluta per gli appassionati di running."
	}, {
		"title": "Hanwag Banks Winter: l'evoluzione a prova di inverno di un classico del trekking",
		"metadata": {
			"source": "joyfreepress.com",
			"keywords": ["Causa", "Boschi", "Trekking", "Ormoni", "Montagna", "Monaco di Baviera", "Gomma", "Pneumatici", "CAI", "Brina", "Calore", "Scarpe", "Moto", "Neve", "Clima", "Solai", "Sasha Banks", "Aria", "Pioggia", "Psicologia", "Sentiero", "Tecnologia", "Collina", "Tempo", "Gore-Tex", "Calzature", "Suola", "Comfort", "Luce", "Suolo", "Inverno", "Uomo", "Meteorologia", "Alluminio", "Bavarese", "Ambiente", "Ghiaccio", "Tomaia", "Temperatura", "Joseph Banks", "Letargo"],
			"url": "https://www.joyfreepress.com/2020/11/27/325626-hanwag-banks-winter-levoluzione-a-prova-di-inverno-di-un-classico-del-trekking/"
		},
		"excerpt": "Per godere appieno del dolce scricchiolio dei nostri passi sul terreno ghiacciato, l'azienda bavarese Hanwag  introduce Banks Winter , il nuovo scarpone invernale progettato per accompagnare in sicurezza e al caldo le uscite della stagione invernale. Le caratteristiche  TOMAIA E RIVESTIMENTO INTERNO  La qualità dei componenti e della lavorazione è tutta orientata alla funzionalità dello scarpone: la nuova versione di Banks Winter è realizzata interamente in pelle nabuk della migliore qualità, certificata LWG Gold, un marchio che certifica le pelli più pregiate e la loro lavorazione sostenibile e rispettosa dell'ambiente. La tomaia in pelle nabuk è abbinata a un rivestimento interno impermeabile in Gore-Tex Partelana, in grado di non far sudare il piede persino nelle uscite più impegnative e di mantenere i piedi caldi e asciutti anche in caso di pioggia, neve e ghiaccio, grazie a un'affidabile membrana a più strati."
	}, {
		"title": "Omaggio di Balenciaga alle sneakers. Le \"Scultura Track\" ricoperte di ottone",
		"metadata": {
			"source": "affaritaliani.it",
			"keywords": ["Pechino", "Beverly Hills", "Scultura", "New York", "Euro", "Parigi", "E-commerce", "Benzene", "Balenciaga", "Milano", "Ottone", "Dubai"],
			"url": "https://www.affaritaliani.it/costume/balenciaga-festeggia-le-sneakers-track2-con-la-scultura-track-707637.html"
		},
		"excerpt": "Il marchio Balenciaga ha anche recentemente aperto una collaborazione con VibramPortachiavi Balenciaga (ph. balenciaga.com) Nell'e commerce del marchio Balenciaga è possibile acquistare la \"Scultura Track\", una scultura in in ottone, ti taglia 41, per celebrare la celebra sneakers firmata Balenciaga Track.2. Il costo è di 4.900 euro ed è disponibile non solo online ma anche negli store di Milano, Parigi, Beverly Hills, New York, Pechino e Dubai."
	}, {
		"title": "Presentazione di \"Va' Sentiero - Alla scoperta del Sentiero Italia\" che include anche la Liguria",
		"metadata": {
			"source": "genovatoday.it",
			"keywords": ["Radio Deejay", "Trekking", "Dresscode", "Emilia-Romagna", "Question time", "YouTube", "Crowdfunding", "Milano", "Mese", "Abbigliamento", "Sentiero Italia", "Liguria", "Foggia", "Trieste", "Economia", "Toscana", "Valle d'Aosta", "Turismo", "Tecnologia", "Piemonte", "Umbria", "Marche", "Cinema", "Trentino-Alto Adige", "Deejay on the Road", "Società", "Prima visione", "Filmmaker", "Friuli-Venezia Giulia", "Veneto", "Lombardia", "Web", "Monti Sibillini", "Web agency", "Fondazione Cariplo", "Ambiente", "Sacco a pelo", "Natura"],
			"url": "https://www.genovatoday.it/eventi/presentazione-va-sentiero.html"
		},
		"excerpt": "Dove Web Indirizzo non disponibile Quando Prezzo Gratis Altre Informazioni facebook.com Redazione  23 novembre 2020 9:37  Mercoledì 25 novembre \"Va' Sentiero\" va in onda con l'anteprima nazionale del docufilm autoprodotto \"Va' Sentiero - Alla scoperta del Sentiero Italia\". Autoprodotto dal progetto \"Va' Sentiero\" con video e montaggio di Andrea Buonopane, il docufilm \"Va' Sentiero - Alla scoperta del Sentiero Italia. I primi 3.548 km da Trieste ai Monti Sibillini\" condensa in 50 minuti i paesaggi, i volti e le storie che il giovane team di Va' Sentiero ha incontrato durante i suoi primi 3.548 km percorsi nei primi 7 mesi della spedizione lungo il Sentiero Italia, attraversando Friuli-Venezia Giulia, Veneto, Trentino-Alto Adige, Lombardia, Piemonte, Valle d'Aosta, Liguria , Toscana, Emilia- Romagna, Umbria e Marche."
	}, {
		"title": "Va' Sentiero: anche la Liguria nel progetto per scoprire il trekking più lungo del mondo",
		"metadata": {
			"source": "genovatoday.it",
			"keywords": ["Albaro", "Radio Deejay", "Trekking", "Dresscode", "Emilia-Romagna", "Question time", "YouTube", "Crowdfunding", "Milano", "Mese", "Centro storico", "Abbigliamento", "Sentiero Italia", "Liguria", "Coronavirus", "Incidente stradale", "Foggia", "Trieste", "Economia", "Toscana", "Valle d'Aosta", "Turismo", "Tecnologia", "Deejay on the Road", "Polizia", "Piemonte", "Umbria", "Marche", "Cinema", "Trentino-Alto Adige", "Monti Sibillini", "Società", "Vaccino", "Prima visione", "Filmmaker", "Friuli-Venezia Giulia", "Veneto", "Lombardia", "Manarola", "Web agency", "Sant'Ilario", "Fondazione Cariplo", "Ambiente", "Sacco a pelo", "Natura", "Autostrada", "Carreggiata"],
			"url": "https://www.genovatoday.it/social/va-sentiero-liguria-trekking.html"
		},
		"excerpt": "Un cammino iniziato con un duplice obiettivo: promuovere il Sentiero Italia - il trekking più lungo al mondo - all'insegna della consapevolezza ambientale e del turismo lento, e valorizzare le Terre Alte rispettandone le peculiarità Valentina Bocchino  23 novembre 2020 08:49  C'è anche la Liguria, con i suoi splendidi panorami montani e le sue bellezze naturali, tra le protagoniste della spedizione \"Va' Sentiero\", il trekking più lungo del mondo alla scoperta del Sentiero Italia portato avanti da 6 giovani che stanno riscoprendo l'entroterra italiano. Mercoledì 25 novembre \"Va' Sentiero\" va in onda con l'anteprima nazionale del docufilm autoprodotto \"Va' Sentiero - Alla scoperta del Sentiero Italia\". Ci pensa il comico Andrea Di Marco con il Movimento Estremista Ligure Coronavirus Albaro: festa di compleanno notturna a Villa Gambaro, i residenti chiamano la polizia Coronavirus Coronavirus: 761 nuovi casi e altre 20 vittime, la più giovane di 44 anni Coronavirus Nel centro storico \"già zona rossa\", la protesta della ristoratrice: 'Chiudiamo da soli, il take away non serve a nulla' Ultime di Oggi Coronavirus Spiagge e passeggiate chiuse, Apparizione e Sant'Ilario prese d'assalto Incidenti stradali Incidente in uno scambio di carreggiata, autostrada bloccata Coronavirus Covid, tre nuovi drive through: soluzione che piace anche per i vaccini Coronavirus Coronavirus: i guariti superano ancora i nuovi contagi, altre 14 vittime Potrebbe interessarti In primo piano social Va' Sentiero: anche la Liguria nel progetto per scoprire il trekking più lungo del mondo social Dialetto curioso: cosa vuol dire \"Maìn che néutte\"?"
	}, {
		"title": "Varese, Vibram scommette sull'intelligenza artificiale",
		"metadata": {
			"source": "QN Il Giorno - Nazionale",
			"keywords": ["Informazione", "Materiale", "Azienda", "Qualità", "Carri armati", "Tempo", "Gomma", "Intelligenza artificiale", "Calzature", "Lavoro", "Produzione", "Robot", "Cooperazione", "Robotica"]
		},
		"excerpt": "I COLOSSI Vibram, colosso varesino che realizza suole per calzature, è tra le venti imprese che hanno avuto accesso ai finanziamenti del bando di Made (categoria grandi imprese). I robot svolgeranno attività ripetitive mentre l'intelligenza artificiale sarà applicata alla veicolazione delle informazioni tramite la raccolta di immagini. Una rivoluzione che consente di evitare rischi come ustioni e assicura benefici come il controllo in tempo reale della corretta sequenza delle operazioni e l'ottimizzazione dei tempi."
	}, {
		"title": "Trail running, ecco le scarpe più tecniche e performanti per i runner esperti",
		"metadata": {
			"source": "gazzetta.it",
			"keywords": ["Mondo", "Settimana", "ARC", "Instagram", "Bussola", "Calzature", "Ultra-Trail du Mont-Blanc", "La bussola d'oro", "Trail running", "Tecnologia"],
			"url": "https://running.gazzetta.it/test/16-11-2020/trail-running-ecco-le-scarpe-piu-tecniche-e-performanti-per-i-runner-esperti-73289"
		},
		"excerpt": "Pierfrancesco Catucci 16 Novembre 2020Abbiamo selezionato le migliori proposte dei principali brand adatte agli appassionati di trail alla ricerca della massima performance Salvato nella pagina \"I miei bookmark\" Commenta Gazzetta Active: tutte le notizie Running: tutte le notizie Salvato nella pagina \"I miei bookmark\" Salvato nella pagina \"I miei bookmark\" Trail_Running_Grit-X_Male-2_RGB_Expires_April_2023 Prosegue il viaggio della Bussola di Active nel mondo del trail running . Dopo una settimana in cui ci siamo spostati più sull'attualità con le mascherine progettate per chi pratica sport , riprendiamo il filone che avevamo lanciato tre settimane fa con i modelli di scarpe dedicati ai principianti del trail , proseguito la settimana successiva con le calzature pensate per i runner intermedi (non solo in termini di tecnica, ma anche di distanze percorse). III UTMB limited edition The North Face Flight Vectiv Arc'teryx Norvan LD 2 Dynafit Feline Up Pro Asics Fujitrabuco Lyte Brooks Catamount Mizuno Wave Hayate 6 Le puntate precedenti della Bussola di Active Leggi i Running: tutte le notizie Active: tutte le notizie  16 Novembre 2020 - 08:01"
	}, {
		"title": "Anna Ferrino neo presidente di Assosport",
		"metadata": {
			"source": "corriere.it",
			"keywords": ["Economia", "Famiglia", "Provincia di Torino", "Pandemia", "Benna", "Montagna", "Euro", "Presidente", "Redazione", "Associazione", "Industria", "Marca", "Fondazione CRT", "L'Unione", "Azienda", "Società per azioni", "Torino"],
			"url": "https://torino.corriere.it/economia/20_novembre_11/anna-ferrino-neo-presidente-assosport-b62c3a0c-2466-11eb-91a0-a6d59c8b2360.shtml"
		},
		"excerpt": "Torinese, classe 1962, Anna Ferrino è ad dell'omonima azienda di famiglia, tende e articoli tecnici da montagna, che quest'anno ha festeggiato i 150 anni di attività di Christian Benna Anna Ferrinoshadow Le tende di Torino in cima ad Assosport. Anna Ferrino, al timone di Ferrino & C Spa, è stato nominata presidente dell'Associazione nazionale dei produttori di articoli sportivi, che riunisce 120 aziende italiane in rappresentanza di 300 brand, 9.300 addetti e 5 miliardi di euro per giro d'affari. Oltre al nuovo presidente, nel corso dell'assemblea Generale 2020 di Assosport sono stati scelti anche i nuovi tre vice presidenti dell'associazione."
	}, {
		"title": "Ferrino alla guida di Assosport: \"La trasformazione digitale per la ripresa\"",
		"metadata": {
			"source": "affaritaliani.it",
			"keywords": ["Industria", "Azienda", "Logistica", "Euro", "Web marketing", "Filiera", "Redazione", "Digitalizzazione", "Boussac", "Lockdown", "Sport", "Abbigliamento", "Distribuzione", "Presidente", "Trasformazione digitale", "Università Ca' Foscari", "Pandemia", "Imprenditori", "Fondazione CRT", "Impresa", "Industria tessile", "Società", "Rivoluzione copernicana", "Torino", "Onda d'urto", "Investimenti", "Calzature", "Lavoro", "Produzione", "E-commerce", "Internet", "Fatturato", "Tessuto", "Marchio", "Amministratore delegato", "Tessitura", "Columbia Pictures", "Marketing", "Società per azioni", "Business development"],
			"url": "https://www.affaritaliani.it/sport/ferrino-alla-guida-di-assosport-la-trasformazione-digitale-per-la-ripresa-705798.html"
		},
		"excerpt": "Spa succede a Federico De Ponti, founder e CEO di Boxeur Des Rues, al timone dei produttori di articoli sportiviAnna Ferrino L'Assemblea Generale 2020 di Assosport ha visto l'elezione di Anna Ferrino a nuova presidente e la presentazione dei risultati della ricerca condotta tra gli associati dall'Osservatorio nazionale \"Digitale e Sport\": la trasformazione digitale è la chiave per ripartire, dopo la contrazione derivata dal Covid-19. Assosport darà agli imprenditori tutto il supporto necessario per cavalcare l'onda di questa rivoluzione copernicana', ha detto Anna Ferrino , neoeletta presidente dell'Associazione Nazionale fra i Produttori di Articoli Sportivi - 120 aziende in tutta Italia per oltre 300 brand, 9.300 addetti, un fatturato aggregato che sfiora i 5 miliardi di euro, pari quasi al 40 per cento del totale messo a segno nel 2019 da tutta l'industria italiana dello sport.Chi è Anna Ferrino - nuova Presidente di Assosport Torinese, classe 1962, Anna Ferrino è amministratore delegato dell'azienda Ferrino & C. Questa l'indagine svolta da \"Digitale e sport\", un osservatorio nazionale costituito da Assosport e dal Digital Enterprise Lab dell' Università Ca' Foscari di Venezia , mirata a fotografare l'attuale grado di maturità digitale delle aziende che operano nel mondo dello sport e le relative conseguenze sul business."
	}, {
		"title": "Assosport: \"Ecco come ci siamo difesi\"",
		"metadata": {
			"source": "repubblica.it",
			"keywords": ["Tessuto", "Azienda", "Logistica", "Euro", "Web marketing", "Filiera", "Digitalizzazione", "Boussac", "Lockdown", "Sport", "Trasformazione digitale", "Abbigliamento", "Distribuzione", "Presidente", "Società", "Università Ca' Foscari", "Pandemia", "Imprenditori", "Fondazione CRT", "Impresa", "Industria tessile", "Rivoluzione copernicana", "Torino", "Onda d'urto", "Confindustria", "Investimenti", "Calzature", "Lavoro", "Produzione", "E-commerce", "Internet", "Fatturato", "Giornalismo", "Ferrino", "Rif", "Industria", "Marchio", "Amministratore delegato", "Tessitura", "Columbia Pictures", "Marketing", "Società per azioni", "Business development"],
			"url": "https://www.repubblica.it/economia/rapporti/osserva-italia/mercati/2020/11/11/news/assosport_ecco_come_ci_siamo_difesi_-274025465/"
		},
		"excerpt": "Presentati i risultati della ricerca condotta tra gli associati dall'Osservatorio nazionale \"Digitale e Sport\" 11 Novembre 2020 Anna Ferrino, neo presidente Assosport  'L'epoca che stiamo vivendo ha imposto alle aziende dello sport una rilettura del proprio modello di business. Neo eletta presidente dell'Associazione Nazionale fra i Produttori di Articoli Sportivi - 120 aziende in tutta Italia per oltre 300 brand, 9.300 addetti, un fatturato aggregato che sfiora i 5miliardi di euro, pari quasi al 40 per cento del totale messo a segno nel 2019 da tutta l'industria italiana dello sport - non ha dubbi: per agganciare la ripresa, gli imprenditori dovranno seguire il faro della trasformazione digitale, binario che interseca il cammino verso una ancora più massiccia internazionalizzazione dei prodotti manifatturieri e un sempre più significativo arruolamento di giovani di talento nelle aziende. Questa l'indagine svolta da \"Digitale e sport\", un osservatorio nazionale costituito da Assosport e dal Digital Enterprise Lab dell'Università Ca' Foscari di Venezia, mirata a fotografare l'attuale grado di maturità digitale delle aziende che operano nel mondo dello sport e le relative conseguenze sul business*."
	}, {
		"title": "La moda sul ghiacciaio del Rodano impacchettato contro il riscaldamento globale",
		"metadata": {
			"source": "corriere.it",
			"keywords": ["Guanti", "Montagna", "Borse", "Acciaio inossidabile", "Riscaldamento globale", "Tanzania", "Midway Games", "Svizzera", "Ghiacciaio del Rodano", "Titanio", "Accessori", "Bisfenolo A", "T-shirt", "Cortina d'Ampezzo", "Giochi olimpici invernali", "Ambientalismo", "Acqua", "Estetica", "Bally", "Sentiero", "Kilimangiaro", "Himalaya", "Gas", "Prêt-à-porter", "Ghiacciaio", "Cappello", "Rodano", "Suola", "Ultravioletto", "Ghiacciai alpini", "Arrampicata", "Curling", "Michel Comte", "Passo della Furka", "Alluminio", "Cintura", "Sostenibilità", "Filato", "Unione europea", "Sciarpa"],
			"url": "https://www.corriere.it/moda/20_novembre_10/moda-ghiacciaio-rodano-impacchettato-salvarlo-riscaldamento-globale-4d3eac9a-2372-11eb-852a-fddf3d627dac.shtml"
		},
		"excerpt": "Tutti i proventi andranno a favore della Bally Peak Outlook Foundation impegnata a salvaguardare le catene montuose più fragili con spedizioni ecologiche (di ripulitura dalle immondizie) di Maria Teresa Venezianishadow  Il ghiacciaio del Rodano in Svizzera impacchettato come un'opera di Cristo (per salvaguardarlo dal riscaldamento globale) è lo scenario. La moda è intenzionata a farsi portabandiera del nuovo pensiero ecologista: Bally lancia la capsule collection Peak Outlook i cui proventi saranno devoluti interamente all'omonimo progetto dell'azienda svizzera impegnata a salvaguardare le catene montuose più fragili del mondo. Etica ed estetica La capsule in edizione limitata della maison svizzera comprende 37 prodotti eco-compatibili la cui vendita servirà a sostenere la Bally Peak Outlook Foundation, istituita all'inizio di quest'anno con l'obiettivo di alimentare le spedizioni per la conservazione delle montagne in tutto il mondo."
	}, {
		"title": "Bally lancia una capsule per la sostenibilità - MFFashion.com",
		"metadata": {
			"source": "mffashion.com",
			"keywords": ["Svizzera", "Midway Games", "Curling", "Ghiacciaio del Rodano", "Titanio", "Microsoft Outlook", "Abbigliamento", "T-shirt", "Stivali", "Giappone", "Bally", "Cambiamenti climatici", "Arrampicata", "Prodotti ecologici", "Suola", "Riscaldamento globale", "Veicolo spaziale", "Passo della Furka", "Michel Comte", "Tecnologia", "Uomo", "Alluminio", "Carbonio", "Sostenibilità"],
			"url": "https://www.mffashion.com:443/news/livestage/bally-lancia-una-capsule-per-la-sostenibilita-202011091032505842"
		},
		"excerpt": "Il ricavato della vendita andrà all'ente benefico Bally peak outlook foundationdi Camilla Bordoni09/11/2020 Home Livestage Bally lancia una capsule per la sostenibilità Condividi: Uno scatto della campagna Bally Bally continua a implementare il suo piano di sostenibilità. Per sensibilizzare i consumatori sul tema del riscaldamento climatico, il fashion brand svizzero lancia la capsule in edizione limitata Peak outlook, fotografata dall'attivista ambientale Michel Comte sul ghiacciaio del Rodano, vicino al passo della Furka. (riproduzione riservata) Lista news"
	}],
	"id": "news"
}, {
	"title": "Principali esponenti aziendali",
	"head": [{
		"key": "Nome",
		"value": "Nome"
	}, {
		"key": "Cognome",
		"value": "Cognome"
	}, {
		"key": "Data di nascita",
		"value": "Data di nascita"
	}, {
		"key": "Codice Fiscale",
		"value": "Codice Fiscale"
	}, {
		"key": "Ruoli",
		"value": "Ruoli"
	}],
	"body": [{
		"Nome": "Daniele Marcello",
		"Cognome": "Ferrario",
		"Data di nascita": "38 anni (7/7/1982)",
		"Codice Fiscale": "FRRDLM82L07Z133E",
		"Ruoli": "<span class='person_role'><strong>sindaco supplente</strong> dal 28/6/2013</span>"
	}, {
		"Nome": "Sara",
		"Cognome": "Bardelli",
		"Data di nascita": "39 anni (18/7/1981)",
		"Codice Fiscale": "BRDSRA81L58B300D",
		"Ruoli": "<span class='person_role'><strong>procuratore</strong> dal 5/5/2016</span>"
	}, {
		"Nome": "Davide",
		"Cognome": "Canciani",
		"Data di nascita": "47 anni (26/7/1973)",
		"Codice Fiscale": "CNCDVD73L26H264D",
		"Ruoli": "<span class='person_role'><strong>procuratore speciale</strong> dal 13/12/2017</span>"
	}, {
		"Nome": "Gianmarco",
		"Cognome": "Ricci",
		"Data di nascita": "48 anni (16/7/1972)",
		"Codice Fiscale": "RCCGMR72L16D612P",
		"Ruoli": "<span class='person_role'><strong>procuratore speciale</strong> dal 16/9/2020</span>"
	}, {
		"Nome": "Massimo",
		"Cognome": "Bertozzi",
		"Data di nascita": "50 anni (24/11/1970)",
		"Codice Fiscale": "BRTMSM70S24H294H",
		"Ruoli": "<span class='person_role'><strong>procuratore</strong> dal 3/7/2019</span>"
	}],
	"id": "table",
	"type": "people"
}, {
	"id": "dashboard",
	"title": "Procurement dashboard",
	"metadata": [{
		"label": "Presenza di eventi",
		"value": "No"
	}, {
		"label": "Data ultimo bilancio",
		"value": "31/12/2019"
	}, {
		"label": "Valutazione complessiva",
		"value": 89
	}, {
		"label": "Classe di rischio",
		"value": 2
	}],
	"score": {
		"items": [{
			"label": "PN/PASSIVO",
			"values": [{
				"year": 2019,
				"value": 64.8
			}, {
				"year": 2018,
				"value": 63.21
			}, {
				"year": 2017,
				"value": 66.17
			}],
			"last_value": 89
		}, {
			"label": "Current ratio",
			"values": [{
				"year": 2019,
				"value": 216.11
			}, {
				"year": 2018,
				"value": 196.06
			}, {
				"year": 2017,
				"value": 206.7
			}],
			"last_value": 77
		}, {
			"label": "CF/ATTIVO",
			"values": [{
				"year": 2019,
				"value": 17.64
			}, {
				"year": 2018,
				"value": 19.66
			}, {
				"year": 2017,
				"value": 18.18
			}],
			"last_value": 89
		}, {
			"label": "OF/Ricavi",
			"values": [{
				"year": 2019,
				"value": 0.07
			}, {
				"year": 2018,
				"value": 0.08
			}, {
				"year": 2017,
				"value": 0.08
			}],
			"last_value": 95
		}]
	},
	"sector": {
		"current_class": 2,
		"items": [{
			"label": 1,
			"last_value": 1,
			"values": [],
			"color": "#00ff99"
		}, {
			"label": 2,
			"last_value": 58,
			"values": [],
			"color": "#00ff99"
		}, {
			"label": 3,
			"last_value": 170,
			"values": [],
			"color": "#00ff99"
		}, {
			"label": 4,
			"last_value": 144,
			"values": [],
			"color": "#00ff99"
		}, {
			"label": 5,
			"last_value": 99,
			"values": [],
			"color": "#00ff99"
		}, {
			"label": 6,
			"last_value": 54,
			"values": [],
			"color": "#ffff00"
		}, {
			"label": 7,
			"last_value": 54,
			"values": [],
			"color": "#ffff00"
		}, {
			"label": 8,
			"last_value": 17,
			"values": [],
			"color": "#ff3300"
		}, {
			"label": 9,
			"last_value": 6,
			"values": [],
			"color": "#ff3300"
		}, {
			"label": 10,
			"last_value": 3,
			"values": [],
			"color": "#ff3300"
		}]
	}
}, {
	"title": "Score",
	"items": [{
		"value": 0.7,
		"score": "innovation",
		"last_update": "2020-11-24"
	}, {
		"value": 0.96,
		"score": "web_centrality",
		"last_update": "2020-11-24"
	}, {
		"value": 1,
		"score": "foreign_market",
		"last_update": "2020-11-26"
	}],
	"id": "score"
}, {
	"last_update": "12/7/2019",
	"number": 4,
	"total_participation_value": 61005.11,
	"values": [{
		"label": "4B GROUP S.R.L.",
		"percentage": 49.99,
		"value": 558012
	}, {
		"label": "PARIS S.R.L.",
		"percentage": 49.99,
		"value": 558012
	}, {
		"label": "Marco Bramani",
		"percentage": 0.01,
		"value": 78
	}, {
		"label": "Silvia Antonia Maria Bramani",
		"percentage": 0.01,
		"value": 78
	}],
	"main_title": "Azionisti",
	"id": "shareholders",
	"include_all": true
}]