import { DataSource } from '@n7-frontend/core';
export class ShareholdersDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'shareholders')) {
            return;
        }
        const shareholdersData = data.find(element=> element.id === 'shareholders');
        return shareholdersData;
    }
}
