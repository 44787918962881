import { DataSource } from '@n7-frontend/core';
export class ChartsCol3SectionTitleDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'charts-col-3')) {
            return;
        }
        const indexChartData = data.find(element => element.id === 'charts-col-3');
        return indexChartData.title;
    }
}
