import { DataSource } from '@n7-frontend/core';
export class ChartsCol2DataDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'charts-col-2')) {
      return;
    }
    const indexChartData = data.find(element => element.id === 'charts-col-2');
    const widgets = indexChartData.items.map(this.createWidget)
    return widgets;
  }

  private createWidget(data, index) {
    const items = data.items;
    if (!items || !items.values || items.values.length === 0) {
      return;
    }
    let increment = 0;
    let lastValue = items.last_value;
    let showSubtitle = true;
    let subText = data.title;

    const l = items.values.length;
    const last = l > 0 ? items.values[l - 1].value : null;
    const beforelast = l > 1 ? items.values[l - 2].value : 1;
    increment = Math.round((last - beforelast) * 100 / beforelast);

    if (!last) { return; }

    if (!isFinite(increment)) { showSubtitle = false; }
    return {
      // icon: 'n7-icon-earth',
      text: last,
      subtitle: showSubtitle ? {
        text: subText,
        icon: increment > 0 ? 'n7-icon-caret-up' : 'n7-icon-caret-down',
        value: increment + '%',
        payload: 'view percent tooltip '
      } : null,
      payload: 'view earth tooltip',
      classes: increment > 0 ? 'is-positive' : 'is-negative'
    };
  }
}
