import { DataSource } from '@n7-frontend/core';
export class DashboardTitleDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'dashboard')) {
      return;
    }
    const dashboardTitle = data.find(element => element.id === 'dashboard');
    const title = {
      main: {
        title: {
          main: {
            text: dashboardTitle.title,
            classes: 'bold'
          }
        }
      }
    }
    return title;
  }
}
