import { DataSource } from '@n7-frontend/core';
export class ChartsCol3DataDS extends DataSource {

  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'charts-col-3')) {
      return;
    }
    const indexChartData = data.find(element => element.id === 'charts-col-3');
    const widgets = indexChartData.items.map(this.createWidget)
    return widgets;
  }
  private createWidget = (data, index)=> {
    const items = data.items;
    if (!items || typeof items.last_value === 'undefined') {
      return;
    }
    if (['increasing', 'decreasing', 'stable', 'incalculable'].includes(data.items.last_value)) {
      return this.getIcon(data.items.last_value);
    } else {
      return this.getValue(data.items.last_value);
    }
  }
  private getValue = (data) => {
    return {
      text: data,
      payload: 'view earth tooltip',
    };
  }
  private getIcon = (value: 'increasing' | 'decreasing' | 'stable' | 'incalculable') => {
    const iconTypeTable =
    {
      'increasing': 'n7-icon-caret-up',
      'decreasing': 'n7-icon-caret-down',
      'stable': 'n7-icon-minus',
      'incalculable': 'n7-icon-equal'
    };
    return {
      icon: iconTypeTable[value],
      payload: 'view earth tooltip',
    };
  }
}
