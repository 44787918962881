import { Directive, ViewContainerRef, Renderer2, ElementRef,  QueryList, AfterViewInit, OnInit } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Directive({
    selector: '[page-break]',
})
export class PageBreakDirective implements AfterViewInit, OnInit {
  

    private PAGE_HEIGHT: number = 1123;
    private HEADER: number = 100;
    private FOOTER: number = 100;
    private SPACE_PER_PAGE: number = this.PAGE_HEIGHT - this.HEADER - this.FOOTER;
    private employedPageSpace = 0;
    pageBreakChildren : QueryList<any>;
    constructor(public viewContainerRef: ViewContainerRef, private elRef: ElementRef, private renderer: Renderer2, private chartService: ChartService) { }


    ngOnInit(): void {
        this.chartService.notReadyForPrint();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const sections = this.getSections();
            this.addSectionsPageBreak(sections);
            this.chartService.readyForPrint();
        }, 5000)
        
    }

    private addSectionsPageBreak(sections) {
        for (const section of sections) {
            let sectionHeight = this.getSectionHeigth(section);
            if (this.isPageSpaceAvailable(sectionHeight)) {
                this.employedPageSpace += sectionHeight + 10;
                //TODO aggiungere pagebreak percentuale.
            } else {
                this.addPageBreakToSection(section);
            }
        }
    }

    private getSections(){
        const results = this.viewContainerRef.element.nativeElement.querySelectorAll('[section]');
        return results;
    }

    private getSectionHeigth(section) {
        let sectionPosition = this.getPosition(section);
        let sectionHeight = this.getHeight(sectionPosition);
        return sectionHeight;
    }

    private getPosition(domElement) {
        var rect = domElement.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {
            top: rect.top + scrollTop,
            bottom: rect.bottom + scrollTop,
            rigth: rect.right + scrollLeft,
            left: rect.left + scrollLeft
        };
    }

    private getHeight(elementPosition){
        return elementPosition.bottom - elementPosition.top ;
    }
    
    private isPageSpaceAvailable(height){
        return (height + this.employedPageSpace) <= this.SPACE_PER_PAGE;
    }

    private addPageBreakToSection(section){
        if(this.hasInnerBreakPoint(section)){
            let innerSections = this.getInnerSections(section);
            this.addSectionsPageBreak(innerSections);
        } else {
            let sectionHeight = this.getSectionHeigth(section);
            this.employedPageSpace = (sectionHeight % this.SPACE_PER_PAGE);
            this.addPageBreakBeforElement(section);
        }
    }

    private hasInnerBreakPoint(element){
        return this.getInnerSections(element).length;
    }

    private getInnerSections(element){
        const results = element.querySelectorAll('[break-section]');
        return results;
    }

    private addPageBreakBeforElement(domElement) {
        const div = this.renderer.createElement('div');
        this.renderer.addClass(div, 'pagebreak');
        const parentNode = this.renderer.parentNode(domElement);
        this.renderer.insertBefore(parentNode, div, domElement);
    }


}

