import { DataSource } from '@n7-frontend/core';
import { Utils } from 'src/app/common/utils';
import { createChart, createDataWidget } from './anagrafica-utils';
export class AnagraficaRevenueDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'anagrafica')) {
      return;
    }
    const anagrafica = data.find(element => element.id === 'anagrafica');
    const chart = createChart(anagrafica.revenue,'anagrafica-revenue-chart')
    const revenueData = createDataWidget(anagrafica.revenue, 'Ricavi');
    const title = Utils.createInnerTitle('Ricavi', Utils.buildLastUpdateSubtext(anagrafica.revenue));
    return {chart:chart, data: revenueData, title: title};
  }
}
