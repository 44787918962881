import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'nested-list',
    templateUrl: './nested-list.component.html'
})
export class NestedListComponent implements OnInit {

    @Input() lb: any;

    constructor() { }

    ngOnInit() {}

}
