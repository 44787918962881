import { Component, OnInit, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'anagrafica',
    templateUrl: './anagrafica.component.html'
})
export class AnagraficaComponent implements OnInit {

    @Input() lb: any;
    images = [];
    constructor(private chartService: ChartService) { }
    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['print'].ds.out$.subscribe(data => {
            if (data) {
                setTimeout(() => {
                        this.chartService.convertToImage(['anagrafica-revenue-chart','anagrafica-employees-chart'], this.images).then(() => {
                            this.chartService.readyForPrint();
                        });
                }, this.chartService.waitTime);
            }
        });
    }
}