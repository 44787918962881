import { DataSource } from '@n7-frontend/core';
import { CgsEnums } from 'src/app/common/cgs-enums';
export class CovidScoreDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'covid')) {
      return;
    }
    const covidData = data.find(element => element.id === 'covid');
    if(!covidData.score) return;
    const covidScoreChart = this.createChart(covidData.score);
    const covidScoreTitle = this.createTitle(covidData.score);
    return { chart: covidScoreChart, title: covidScoreTitle};
  }
  private createChart = (data) => {
    if (!data) {
      return;
    }
    const enums = new CgsEnums();
    return {
      containerId: 'covid-score-chart',
      width: 200,
      height: 20,
      value: data.value,
      label: enums.getClassLabel(data.value),
      intervals: enums.getClassIntervals(),
      gutter: 2,
      colors: enums.getClassColors()
    }
  }
  private createTitle = (data) => {
    if (!data) {
      return;
    }
    return {
      title: {
        main: {
          text: data.title || 'Cerved Group Score',
          classes: 'bold'
        }
      }
    };
  }
}