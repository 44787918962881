import { DataSource } from '@n7-frontend/core';
export class CovidTitleDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'covid')) {
      return;
    }
    const covidData = data.find(element => element.id === 'covid');
    return {
      title: {
        main: {
          text: covidData.title,
          classes: 'bold'
        },
      }
    };
  }
}
