import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html'
})
export class ChartComponent implements OnInit {
  @Input() chartdata;
  @Input() image;
  @Input() specialEmpty = false;
  constructor() { }
  ngOnInit() {}
}
