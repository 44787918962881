
import { Component, Input, AfterContentChecked } from '@angular/core';
/**
 * Interface for FeverChartComponent's "data"
 *
 * @property containerId (required)
 * @property containerWidth (required)
 * @property containerHeight (required)
 * @property feverChartData (required)
 * @property classes (optional)
*/
export interface FeverChartData {
    /**
     * unique identifier for the fever-chart
     */
    containerId: string;
    /**
     * total width for the fever-chart
     */
    width: number;
    /**
     * total height for the fever-chart
     */
    height: number;
    /**
     * intervals
     */
    intervals?: Range[]
    /**
     * colors for the fever-chart
     */
    colors: string[];
    /**
     * gutter
     */
    gutter: number;

    /**
     * current value
     */
    value: number;
    /**
     * label for current value
     */
    label: string,
    /**
     * additional css classes
     */
    classes?: string;
}

export type Range = [number, number];


@Component({
    selector: 'n7-fever-chart',
    templateUrl: './fever-chart.html',
})
export class FeverChartComponent implements AfterContentChecked {
    @Input() data: FeverChartData;
    @Input() emit: any;
    private d3;
    private _loaded = false;
    private width;
    private height;
    private gutter;
    private value;
    private containerId;
    private intervals;
    private colors;
    public label: string;
    public labelColor: string;
    private range: Range = [0, 100];
    private segmentWidth;
    ngAfterContentChecked() {
        if (!!this.data) {
            if (this._loaded) return;
            this.initData(this.data);
            setTimeout(() => {
                import('d3').then((module) => {
                    this.d3 = module;
                    this.draw();
                });
            });
        }
    }
    private initData = (data) => {
        const { width, height, gutter, value, containerId, intervals, colors, label } = data;
        this.width = width;
        this.height = height;
        this.gutter = gutter;
        this.value = value;
        this.containerId = containerId;
        this.intervals = intervals;
        this.colors = colors;
        this.label = label;
        this.segmentWidth = this.width / this.colors.length - this.gutter;
        this._loaded = true;
    }
    draw = () => {
        if (this.colors.length < 2) return;
        if (!this.intervals) {
            this.intervals = this.buildIntervals(this.colors);
        }
        const intervalIndex = this.getIntervalIndexByValue(this.intervals, this.value);
        this.labelColor = this.colors[intervalIndex];
        const canvas = this.d3.select(`#${this.containerId}`)
        this.drawSegments(canvas);
        this.drawCaret(canvas, intervalIndex);
    }
    private buildIntervals = ( colors) => {
        const step = (Math.trunc(100 / colors.length));
        const intervals = []
        let lastStep = -1;
        for (let i = 0; i < colors.length; i++) {
            let min = lastStep +1
            let max = lastStep + step;
            if((i+1)>=colors.length){
                max = 100;
            }
            intervals.push([min, max])
            lastStep += step; 
        }
        return intervals;
    }
    private drawSegments = (canvas) =>{
        const segments = canvas.selectAll('rect').data(this.colors);
        segments
        .enter()
        .append('rect')
        .attr('width', this.segmentWidth)
        .attr('height', this.height)
        .attr('fill', (d, i) => d)
        .attr('y', 10)
        .attr('x', (d, i) => (this.segmentWidth + this.gutter) * i + 1);
    }
    private drawCaret = (canvas, intervalIndex) =>{
        const caret = canvas.selectAll('path').data([this.value]);
        const caretData = "M -20,0 L0,-30 20,0 0,-7, -20,0";
        caret
            .enter()
            .append('path')
            .attr('d', caretData)
            .attr('fill', '#222222')
            .attr('stroke', '#222222')
            .attr(
                'transform',
                `translate(${(this.segmentWidth + this.gutter) * intervalIndex +
                this.segmentWidth / 2}, ${10 + this.height}) scale(.3)`);
    }
    private getIntervalIndexByValue = (intervals, value) => {
        if (!Array.isArray(intervals) || !value) {
            return -1;
        }
        let index = intervals.findIndex((item) => { return value >= item[0] && value <= item[1]; });
        return index;
    }
}
