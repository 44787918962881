import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'news',
    templateUrl: './news.component.html'
})
export class NewsComponent implements OnInit {

    @Input() lb: any;

    constructor() { }

    ngOnInit() {}

}
