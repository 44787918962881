import { DataSource } from '@n7-frontend/core';
import { Utils } from 'src/app/common/utils';
export class DashboardTrendDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'dashboard')){
      return;
    }
    const dashboardData = data.find(element => element.id === 'dashboard');
    if(!dashboardData.score || !dashboardData.score.items){
      return;
    }
    const charts = dashboardData.score.items.map(this.createWidget);
    const title = Utils.createInnerTitle("Trend");
    return {charts, title};
  }
  private createWidget = (data, index) => {
    return {
      chart: this.createChart(data, index),
      title: {
        title: {
          main: {
            text: data.label,
            classes: 'bold'
          }
        },
      }
    }
  } 
  private createChart = (data, index) => {
    if (!data.values || data.values.lenght === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }
    const values = data.values.map(x => x.value);
    const labels = data.values.map(x => x.year);
    const chartOption = {
      chart: {
        id: 'dashboard-trend-chart-'+index,
        type: 'bar',
        fontFamily: 'Open Sans',
        animations: {
          enabled: false
        },
        height: 200,
        width: 200,
        toolbar: {
          show: false
        }
      },
      series: [{
        data: values
      }],
      plotOptions: {
        bar: {
          columnWidth: '70%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways:false
        },
        categories: labels,
        tickPlacement: 'on'
      },
      yaxis: {
      },
      fill: {
        type: 'linear',
        colors: ['#1F5871'],
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        }
      }
    };
    return {
      containerId: 'dashboard-trend-chart-'+index,
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}
