import { DataSource } from '@n7-frontend/core';
import { Utils } from 'src/app/common/utils';
export class DashboardScoreDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'dashboard')) {
      return;
    }
    const dashboardData = data.find(element => element.id === 'dashboard');
    const chart = this.createChart (dashboardData);
    const title = Utils.createInnerTitle("Composizione dello score");
    return {chart, title};
  }

  private createChart = (data) => {
    if (!data.score || !data.score.items) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }

    const labels = data.score.items.map(item => item.label);
    const values = data.score.items.map(item => item.last_value)
    const chartOption = {
      chart: {
        id: 'dashboard-score-chart',
        type: 'bar',
        fontFamily: 'Open Sans',
        animations: {
          enabled: false
        },
        height: 350,
        width: 400,
        toolbar: {
          show: false
        }
      },
      series: [{
        data: values
      }],
      plotOptions: {
        bar: {
          columnWidth: '70%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways:false
        },
        categories: labels,
        tickPlacement: 'on'
      },
      yaxis: {
      },
      fill: {
        type: 'linear',
        colors: ['#1F5871'],
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        },
      }
    };
    return {
      containerId: 'dashboard-score-chart',
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}
