import { DataSource } from '@n7-frontend/core';
import { ScoreEnums } from 'src/app/common/score-enums';
export class ScoreWidgetsDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'score')) {
            return;
        }
        const scoreData = data.find(element => element.id === 'score');
        const widgets = scoreData.items.map(this.createImage)
        return widgets;
    }
    private createImage = (item, index) => {
        const scoreEnums = new ScoreEnums();
        if (!item || !item.score || !scoreEnums.getScoreNames().includes(item.score)) {
            return;
        }
        const value = Math.trunc(item.value * 100);
        return {
            containerId: `score-widget-${index}`,
            width: 200,
            height: 20,
            value: value,
            label: scoreEnums.getLabelByValue(item.score, value),
            gutter: 2,
            colors: scoreEnums.getColorsByScore(item.score)
        }
    }
}