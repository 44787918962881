import { Component, OnInit, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'charts-col-2',
    templateUrl: './charts-col-2.component.html'
})
export class ChartCol2Component implements OnInit {

    @Input() lb: any;

    images = [];
    constructor(private chartService: ChartService) { }

    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['print'].ds.out$.subscribe(data => {
            if (data) {
                setTimeout(() => {
                        this.chartService.convertToImage(['charts-col-2-widget-0','charts-col-2-widget-1'], this.images).then(() => {
                            this.chartService.readyForPrint();
                        });
                }, this.chartService.waitTime);
            }
        });
    }

}
