import { DataSource } from '@n7-frontend/core';
export class ExtendedCgsDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'extended-cgs')) {
      return;
    }

    const dataCgs = data.find(element => element.id === 'extended-cgs');
    return this.createData(dataCgs);
  }
  private createData(dataCgs) {
    const data = {
      title: {
        main: {
          text: dataCgs.title,
          classes: 'bold'
        }
      },
      item: dataCgs.item
    };
    if (dataCgs.metadata) {
      data['metadata'] = {
        title: {
          main: {
            text: dataCgs.metadata.title,
            classes: 'bold'
          }
        },
        items: dataCgs.metadata.items
      };
    }
    return data;
  }
}