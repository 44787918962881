import { style } from '@angular/animations';
import { DataSource } from '@n7-frontend/core';
import { Utils } from 'src/app/common/utils';
export class DashboardSectorDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'dashboard')) {
      return;
    }
    const dashboardData = data.find(element => element.id === 'dashboard');
    const chart = this.createChart(dashboardData);
    const title = Utils.createInnerTitle("Trend del settore");
    return {chart, title};
  }
  private createChart = (data) => {
    if (!data || !data.sector || !data.sector.items || data.sector.items.length === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }
    const labels = data.sector.items.map(item => item.label);
    const values = data.sector.items.map(item => item.last_value);
    const colors = data.sector.items.map(item => item.color);
    const currentClass = data.sector.current_class;
    const chartOption = {
      chart: {
        id: 'dashboard-sector-chart',
        type: 'bar',
        fontFamily: 'Open Sans',
        animations: {
          enabled: false
        },
        height: 200,
        toolbar: {
          show: false
        }
      },
      subtitle: {
        text: `Classe di rischio dell'azienda ${currentClass}`,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '10px',
          fontFamily: 'Open Sans',
        },
    },    
      series: [{
        data: values
      }],
      plotOptions: {
        bar: {
          columnWidth: '70%',
          distributed: true
        }
      },
      legend: {
        show: false
      },
      colors: colors,
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },

      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways: false,
          style:{
            // colors: colors,
            // fontSize:
            // fontSize:
            // fontFamily:
            // fontWeight:
            // cssClass:
          }
        },
        categories: labels,
        tickPlacement: 'on',
    
      },
      yaxis: {
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "vertical",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: this.calculateOpacity(labels, currentClass ),
          opacityTo: this.calculateOpacity(labels, currentClass ),
          stops: []
        },
      }
    };
    return {
      containerId: 'dashboard-sector-chart',
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
  private calculateOpacity = (labels: string[], currentClass: string): number | number[]=>{
    const defaultOpacity = 0.5;
    if(!labels || !currentClass)  return defaultOpacity;
    const index = labels.findIndex(label=> label === currentClass);
    if(index <=-1) return defaultOpacity;
    const opacityArray = Array.from({length: labels.length}, v=>defaultOpacity);
    opacityArray[index] = 1;
    return opacityArray;
  }
}
