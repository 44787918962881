import { Component, OnInit, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'shareholders',
    templateUrl: './shareholders.component.html'
})
export class ShareholdersComponent implements OnInit {
    @Input() lb: any;

    images = [];
    constructor(private chartService: ChartService) { }

    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['print'].ds.out$.subscribe(data => {
            if (data) {
                setTimeout(() => {
                        this.chartService.convertToImage(['shareholders-chart'], this.images).then(() => {
                            this.chartService.readyForPrint();
                        });
                }, this.chartService.waitTime);
            }
        });
    }

    isDate(d) {
        return d && d != '1900-01-01';
    }

}
