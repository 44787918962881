import { DataSource } from '@n7-frontend/core';
import { Utils } from 'src/app/common/utils';
import { createChart, createDataWidget } from './anagrafica-utils';
export class AnagraficaEmployeesDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'anagrafica')) {
      return;
    }
    const anagrafica = data.find(element => element.id === 'anagrafica');
    const chart = createChart(anagrafica.employees,'anagrafica-employees-chart')
    const dataWidget =  createDataWidget(anagrafica.employees, 'Dipendenti');
    const title = Utils.createInnerTitle('Dipendenti', Utils.buildLastUpdateSubtext(anagrafica.employees));
    return {chart: chart, data: dataWidget, title:title};
  }
}
