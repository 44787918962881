import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'covid',
    templateUrl: './covid.component.html'
})
export class CovidComponent implements OnInit {

    @Input() lb: any;

    constructor() { }

    ngOnInit() {}

}
