import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractLayout, CommunicationService } from '@n7-frontend/boilerplate';
import { PrintLayoutConfig as config } from './print-layout.config';
import { ActivatedRoute } from '@angular/router';
import { ChartService } from 'src/app/services/chart.service';
import * as moment from 'moment';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'print-layout',
  templateUrl: './print-layout.html'
})
export class PrintLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {

  pdfLogo;
  footerText;
  constructor(
    private communication: CommunicationService,
    private activatedRouter: ActivatedRoute,
    private chartService: ChartService
  ) {
    super(config);
    const date = moment().format('DD/MM/YY HH:mm')
    this.footerText = `Generato in data ${date}`
  }

  protected initPayload() {
    return {
      communication: this.communication,
      router: this.activatedRouter,
    };
  }

  ngOnInit() {
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  readyPrint() {
    return this.chartService.isReadyForPrint();
  }

}
