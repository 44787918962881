import { EventHandler } from '@n7-frontend/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, Observable } from 'rxjs';
import { mock } from './mock';

export class PrintLayoutEH extends EventHandler {

  private communication;
  private auth;
  private router;

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'print-layout.init':
          this.dataSource.onInit(payload);
          this.init(payload);
          this.listenRoute();
          break;
      }
    });
  }

  private init(payload) {
    this.communication = payload.communication;
    this.auth = payload.auth;
    this.router = payload.router;
  }

  private listenRoute() {
    this.router.paramMap.subscribe(x => {
      const id = x.get('requestId');
      let requestFun = this.getData(id);
      if (environment.useMock) {
        requestFun = of(mock);
      }
      requestFun.subscribe(data =>  this.dataSource.updateWidgets(data))
    })
  }

  private getData(id) {
    return this.communication.request$('getData', {
      urlParams: id,
      params: { configurationId: id },
      onError: (error) => { console.log(error) },
      httpOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'x-api-key': "",
          "x-amz-security-token":"8ce527d2-7c8d-4d16-82af-dd0f995f0f83"
        })
      }
    });
  }
}