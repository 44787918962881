import { DataSource } from '@n7-frontend/core';
import { CgsEnums } from 'src/app/common/cgs-enums';
export class ExtendedCgsSubscoreDS extends DataSource {
    private cgsEnums = new CgsEnums();
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'extended-cgs')) {
            return;
        }
        const dataCgs = data.find(element => element.id === 'extended-cgs');
        return this.createData(dataCgs);
    }
    private createData(dataCgs) {
        const data = {
            header: "Valutazioni parziali",
            items: this.createSubscores(dataCgs)
        }
        return data;
    }
    private createSubscores = (dataCgs) => {
        if (!dataCgs.subscores) {
            return;
        }
        const subscores = dataCgs.subscores;
        const c4 = this.createSubscore('C4', subscores['C4']);
        const c3 = this.createSubscore('C3', subscores['C3']);
        const c1 = this.createSubscore('C1', subscores['C1']);
        const c5 = this.createSubscore('C5', subscores['C5']);
        const c6 = this.createSubscore('C6', subscores['C6']);
        return [c4, c3, c1, c5, c6];
    }
    private createSubscore = (subscoreName: string, value: string) => {
        if (!this.cgsEnums.isValidSubscoreValue(subscoreName, value)) {
            return;
        }
        const color = this.cgsEnums.getSubscoreColor(subscoreName, value);
        const label = this.cgsEnums.getSubscoreLabel(subscoreName);
        return {
            label: label,
            color: color,
            value: value,
        };
    }
}