import { DataSource } from '@n7-frontend/core';
export class ChartsCol2SectionTitleDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'charts-col-2')) {
            return;
        }
        const indexChartData = data.find(element => element.id === 'charts-col-2');
        return {
            title: {
                main: {
                    text: indexChartData.title,
                    classes: 'bold'
                }
            },
        };
    }
}
