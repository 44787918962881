import * as moment from 'moment';
export class Utils {

    public static palette = [
        '#0FA5EB',
        '#c090f6',
        '#09AEA2',
        '#567A98',
        '#ABC7EE',
        '#98D1B0',
        '#0FA5EB',
        '#c090f6',
        '#09AEA2',
        '#567A98',
        '#ABC7EE',
        '#98D1B0'
    ];

    public static palette2 = [
        '#1F5871',
        '#185D7D',
        '#578EA8',
        '#7699AA'
    ];

    public static palette3 = [
        '#E43C23',
        '#F6BC41',
        '#52982E'
    ];

    public static minMaxAxis = 20;

    public static getTooltip = (name, percent, value, label, currency = 'euro') => {
        return `<div class="dc-chart-tooltip">
        <div class="dc-chart-tooltip__name">${name}</div>
            <div class="dc-chart-tooltip__data">
                <span class="dc-chart-tooltip__data-value">${percent}%</span>
                <span class="dc-chart-tooltip__data-label">${label}</span>
            </div>`+
            (value ? `<div class="dc-chart-tooltip__data">
                <span class="dc-chart-tooltip__data-value">${value}</span>
                <span class="dc-chart-tooltip__data-label">${currency}</span>
            </div>`: ``) +
            `</div> `;
    }

    public static sortByYear = (arr) => {
        if (Array.isArray(arr)) {
            return arr.sort((a, b) => a.year > b.year ? 1 : a.year < b.year ? -1 : 0);
        }
        return arr;
    }

    public static getMinAbs = (serie, getValue = false) => {
        let min = 1000001;
        serie.forEach(element => {
            const val = getValue ? Math.abs(element.value) : Math.abs(element);
            if (val < min) {
                min = val;
            }
        });
        return min;
    }

    public static HasNoValue(coll) {
        if (!Array.isArray(coll)) { return false; }
        let ret = true;
        coll.forEach(elem => {
            if (elem.value !== 'no_value' && elem.value !== null) {
                ret = false;
            }
        });
        return ret;
    }

    public static approx(value) {
        const absvalue = Math.abs(value);
        if (absvalue > 150) {
            return Utils.bigNumberConverter(Math.round(value));
        } else if (absvalue > 100) {
            return Utils.bigNumberConverter(Math.round(value * 10) / 10);
        } else if (absvalue > 10) {
            return Utils.bigNumberConverter(Math.round(value * 100) / 100);
        } else {
            return Utils.bigNumberConverter(Math.round(value * 1000) / 1000);
        }
    }

    public static getSizeLabelForData = (serie, getValue = false) => {
        const x = Utils.getMinAbs(serie, getValue);
        if (Math.abs(x) > 1000000) { return 'M'; }
        if (Math.abs(x) > 1000) { return 'K'; }
        return '';
    }

    public static getBigNumberDivider = (serie, getValue = false) => {
        const x = Utils.getMinAbs(serie, getValue);
        if (Math.abs(x) > 1000000) { return 1000000; }
        if (Math.abs(x) > 1000) { return 1000; }
        return 1;
    }

    public static bigNumberConverter = (x) => {
        if (Math.abs(x) > 1000000) { return Math.round(x / 100000) / 10 + 'M'; }
        if (Math.abs(x) > 1000) { return Math.round(x / 1000) + 'K'; }
        return x;
    }

    public static bigNumberPoints = (x) => {
        x = x + '';
        const xArr = x.split('');
        for (let i = xArr.length - 3; i > 0; i = i - 3) {
            xArr.splice(i, 0, '.');
        }
        return xArr.join('');
    }


    public static slug(thestring, numOfWord, unionChar = '-') {
        const stringArray: Array<string> = thestring.split(' ', numOfWord);
        return stringArray.join(unionChar);
    }

    public static clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    public static getAge = (birthdate) => {
        // const m = moment(birthdate, 'YYYY-MM-DD');
        const m = moment(birthdate, 'DD/MM/YYYY');
        return moment().diff(m, 'years');
    }

    public static reduceToCommons = (serie1: Array<any>, serie2: Array<any>, firstWithValue = false) => {
        if (!serie2 || !serie2.length) { return { s1: serie1, s2: null }; }
        if (!serie1 || !serie1.length) { return { s1: null, s2: serie2 }; }
        serie1 = Utils.sortByYear(serie1);
        serie2 = Utils.sortByYear(serie2);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < serie1.length; i++) {// tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < serie2.length; j++) {
                if (serie2[j].year === serie1[i].year &&
                    (!firstWithValue || (serie2[j].value && serie1[i].value))
                ) {
                    return { s1: serie1.slice(i), s2: serie2.slice(j) };
                }
            }
        }
        return { s1: null, s2: null };
    }

    public static hasKpiData = (data) => {
        if (data) {
            for (const idx in data) {
                if (data[idx] && data[idx].values && data[idx].values.length) {
                    return true;
                }
            }
        }
        return false;
    }

    public static createInnerTitle = (text, subtext?) => {
        return {
            title: {
                main: {
                    text: text,
                    classes: 'bold'
                },
                secondary: {
                    text: subtext
                }
            }
        }
    }
    public static buildLastUpdateSubtext = (item) => {
        if (!item || !item.last_update) {
            return;
        }
        return `Aggiornato al ${item.last_update}`;
    }
}
