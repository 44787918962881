import { Component, OnInit, Input } from '@angular/core';
@Component({
    selector: 'extended-cgs',
    templateUrl: './extended-cgs.component.html'
})
export class ExtendedCgsComponent {
    @Input() lb: any;

    constructor() { }
}
