import { DataSource } from '@n7-frontend/core';
export class  ScoreSectionTitleDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'score')) {
            return;
        }
        const indexChartData = data.find(element=> element.id === 'score');
        return {
            title: {
                main: {
                  text:  indexChartData.title,
                  classes: 'bold'
                }
              },
        };
    }

}
