import { DataSource } from '@n7-frontend/core';
export class NewsDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'news')){
      return;
    }

    const newsData = data.find(element=> element.id === 'news');
    if(!newsData.items || newsData.items.length === 0){
      return;
    }
    const news = {
      title: {
        main: {
          text: newsData.title || `News`,
          classes: 'bold'
        }
      },
      items: newsData.items.map(this.createNews)
    }
    return news;
  };

  private createNews(data){
    return{
      title: {
        main: {
          text: data.title,
          classes: 'bold'
        }
      },
      metadata: data.metadata,
      excerpt: data.excerpt
    }
  }
}
