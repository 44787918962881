import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'charts-col-3',
    templateUrl: './charts-col-3.component.html'
})
export class ChartsCol3Component implements OnInit {

    @Input() lb: any;

    print = true;

    constructor() { }

    ngOnInit() {}

}
