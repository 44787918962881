import { DataSource } from '@n7-frontend/core';
import { CgsEnums } from 'src/app/common/cgs-enums';
import { Utils } from 'src/app/common/utils';
export class CovidAnalysisDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'covid')) {
      return;
    }
    const covidData = data.find(element => element.id === 'covid');
    if(!covidData.analysis) return;
    const covidTitle = Utils.createInnerTitle('Analisi impatto Covid-19');
    const covidTable = this.createTable(covidData.analysis)
    return {title: covidTitle, table: covidTable};
  }
  private createTable = (values) => {
    if(!values || !Array.isArray(values)) return;
    const newValues = [];
    const cgsEnums = new CgsEnums();
    for (const value of values) {
      const lowColor = cgsEnums.getColorByClass(value.lowScore);
      const hardColor = cgsEnums.getColorByClass(value.hardScore);
      const newValue = { title: value.title, items: value.items, lowColor, hardColor}  
      newValues.push(newValue);
    }
    return newValues;
  }
}
