import { DataSource } from '@n7-frontend/core';
export class AnagraficaLocationsDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'anagrafica')) {
            return;
        }
        const anagraficaData = data.find(element => element.id === 'anagrafica');
        if (!anagraficaData.locations) return;
        const title = 'Sedi'
        return { table: this.createTable(anagraficaData.locations), 
            title: title, 
            total: anagraficaData.locations.number };
    }
    private createTable = (data) => {
        if (!data || !data.values) return;
        const table = data.values.map((this.createTableCells));
        return {
            classes: 'test-layout-table',
            body: table
        };
    }
    private createTableCells = (location) => {
        {
            return {
                classes: 'BODY',
                cells: [
                    {
                        classes: 'CONTENT',
                        content: this.renameLocationLabel(location.label),
                        type: 'html'
                    },
                    {
                        classes: 'CONTENT',
                        content: location.address,
                        type: 'html'
                    }
                ]
            };
        }
    }
    private renameLocationLabel = (loc: string) => {
        if (loc) {
            switch (loc.toLowerCase()) {
                case 'mainaddress': return 'Sede legale';
            }
        } else {
            // loc = 'altro';
        }
        return loc;
    }
}
