import { DataSource } from '@n7-frontend/core';
import { Utils } from '../../common/utils';
export class ChartsCol2WidgetsDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'charts-col-2')) {
      return;
    }
    const indexChartData = data.find(element => element.id === 'charts-col-2');
    const indexChart = indexChartData.items.map(this.createImage)

    return indexChart;
  }

  private createImage(data, index) {
    const items = data.items;
    if (!items || !items.values || items.values.length === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }
    const values = data.items.values.map(x => x.value);
    const categories = data.items.values.map(x => x.year);
    const chartOption = {
      series: [{
        name: data.title,
        data: values
      }],
      colors: ['#1F5871'],
      chart: {
        id: 'charts-col-2-widget-' + index,
        fontFamily: 'Open Sans',
        height: 100,
        animations: {
          enabled: false
        },
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      markers: {
        size: [4],
        strokeWidth: 2,
        hover: {
          size: [6]
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.71,
          opacityTo: 0.01,
        }
      },
      xaxis: {
        categories: categories,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: (min) => min - (Math.abs(min) / Utils.minMaxAxis),
        max: (max) => max + (Math.abs(max) / Utils.minMaxAxis),
        tickAmount: 5,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: 'charts-col-2-widget-' + index,
      libOptions: chartOption,
      title: {
        main: {
          text: data.title,
          classes: 'bold'
        }
      },
      payload: { loaded: true }
    };
  }
}
