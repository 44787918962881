import { DataSource } from '@n7-frontend/core';
import { CgsEnums } from '../common/cgs-enums';
export class EventsDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'events')){
      return;
    }
   const eventsData = data.find(element=> element.id === 'events');
  return this.getEvents(eventsData);
  }
  private getEvents = (data)=>{
    const cgsEnums= new CgsEnums();
    const subscoreName = 'C3';
    const subscore = cgsEnums.getSubscore(subscoreName);
    if(!subscore || !cgsEnums.isValidSubscoreValue(subscoreName, data.score)){
     return;
    }
    return {
     title:{
      title: {
        main: {
          text: data.title,
          classes: 'bold'
        }
      }
     },
     values: subscore.values,
     selected_value: data.score,
     last_update: data.last_update,
     footer_text: data.footer_text
   }
  }
}
