import { DataSource } from '@n7-frontend/core';
export class NestedListDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'nested-list')){
      return;
    }

    const nestedListData = data.find(element=> element.id === 'nested-list');
    return {
      title: {
        main: {
          text: nestedListData.title,
          classes: 'bold'
        }
      },
      subtitle: nestedListData.text,
      items: nestedListData.items.map(this.createSubList)
    };
  }

  private createSubList(list){
    return  {
      title: {
        main: {
          text: list.title,
          classes: 'bold'
        }
      },
      items: list.items
    };
  }
}