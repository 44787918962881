import { DataSource } from '@n7-frontend/core';
import { Utils } from 'src/app/common/utils';
export class AnagraficaSocialDS extends DataSource {
  protected transform(data): any {
    if (!data || !data.find(element => element.id === 'anagrafica')) {
      return;
    }
    const anagraficaData = data.find(element => element.id === 'anagrafica');
    if (!anagraficaData.social) return;
    const title = Utils.createInnerTitle('Social')
    const table = this.createTable(anagraficaData.social);
    return { table: table, title: title };
  }
  private createTable = (social) => {
    if (!social) return;
    const socialLabels = Object.keys(social);
    const table = socialLabels.map(socialLabel => this.createTableCells(socialLabel, social[socialLabel]));
    return {
      classes: 'test-layout-table',
      body: table
    };
  }
  private createTableCells = (label, value) => {
    {
      return {
        classes: 'BODY',
        cells: [
          {
            classes: 'CONTENT',
            content: label,
            type: 'html'
          },
          {
            classes: 'CONTENT',
            content: value,
            type: 'html'
          }
        ]
      };
    }
  }
}
