import { DataSource } from '@n7-frontend/core';
export class ChartsCol3TitleDS extends DataSource {
    protected transform(data): any {
        if (!data || !data.find(element => element.id === 'charts-col-3')) {
            return;
        }
        const indexChartData = data.find(element => element.id === 'charts-col-3');
        const indexChartTitle = indexChartData.items.map(this.createTitle);
        return indexChartTitle;
    }
    private createTitle = (data) => {
        if(!data){
            return;
        }
        return {
            title: {
                main: {
                    text: data.title,
                    classes: 'bold'
                },
                secondary: {
                    text: this.getSecondaryTitle(data.items)
                }
            }
        };
    }
    private getSecondaryTitle = (item) => {
        if (!item || !item.last_update) {
            return;
        }
        return item.last_update
    }
}
