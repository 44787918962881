export class CgsEnums {
    private classes = [
        { class: "8", range: [0, 24], label: 'Non affidabile', color: "#E9463E" },
        { class: "7", range: [25, 34], label: 'Affidabilità Bassa', color: "#F0E647" },
        { class: "6", range: [35, 41], label: 'Affidabilità Contenuta', color: "#D1DEA6" },
        { class: "5", range: [42, 52], label: 'Affidabilità Moderata', color: "#ACCB7D" },
        { class: "4", range: [53, 62], label: 'Affidabilità Media', color: "#9FC358" },
        { class: "3", range: [63, 72], label: 'Affidabilità Buona', color: "#9CC455" },
        { class: "2", range: [73, 82], label: 'Affidabilità Elevata', color: "#3FAB55" },
        { class: "1", range: [83, 100], label: 'Affidabilità Massima', color: "#2FA037" }
    ];
    private subscoreTable = [
        {
            subscore: "C4",
            label: "Eventi negativi",
            values: [
                {
                    label: "Gravissimi",
                    color: "#CB3C2E"
                },
                {
                    label: "Gravi",
                    color: "#EA6343"
                },
                {
                    label: "Limitati",
                    color: "#EAA050",
                },
                {
                    label: "Minimi",
                    color: "#EAC950",
                },
                {
                    label: "Assenti",
                    color: "#2A9765",
                }
            ]
        },
        {
            subscore: "C3",
            label: "Tempi di pagamento",
            values: [
                {
                    label: "Ritardi significativi",
                    class: "4.0",
                    color: "#CB3C2E"
                },
                {
                    label: "Ritardi",
                    class: "3.0",
                    color: "#EAA050"
                },
                {
                    label: "Dilazionati",
                    class: "2.0",
                    color: "#EAC950",
                },
                {
                    label: "Prevalentemente regolari",
                    class: "1.0",
                    color: "#7DC7A1",
                },
                {
                    label: "Regolari",
                    class: "0.0",
                    color: "#2A9765",
                }
            ]
        },
        {
            subscore: "C1",
            label: "Situazione economico-finanziaria",
            values: [
                {
                    label: "Rischio molto elevato",
                    color: "#CA473E"
                },
                {
                    label: "Rischio elevato",
                    color: "#EA6161"
                },
                {
                    label: "Rischio",
                    color: "#EA7243",
                },
                {
                    label: "Rischio moderato",
                    color: "#E7963F",
                },
                {
                    label: "Solvibilità bassa",
                    color: "#E4B638",
                },
                {
                    label: "Solvibilità moderata",
                    color: "#E8CE3C",
                },
                {
                    label: "Solvibilità",
                    color: "#BACF6B",
                },
                {
                    label: "Ampia solvibilità",
                    color: "#8CCA9D",
                },
                {
                    label: "Sicurezza",
                    color: "#5ABC8A"
                },
                {
                    label: "Sicurezza elevata",
                    color: "#2A9765"
                }
            ]
        }, {
            subscore: "C5",
            label: "Rischi connessi al n. consultazioni",
            values: [
                {
                    label: "Segnali di rischio",
                    color: "#CB3C2E"
                },
                {
                    label: "Deboli segnali di rischio",
                    color: "#EAC950"
                },
                {
                    label: "Assenza di segnali di rischio",
                    color: "#2A9765",
                }
            ]
        },
        {
            subscore: 'C6',
            label: "Profilo strutturale",
            values: [
                {
                    label: "Potenziali segnali di rischio",
                    color: "#EAC950"
                },
                {
                    label: "Assenza di segnali di rischio",
                    color: "#2A9765"
                }
            ]
        },
    ];
    private sortMaps = () => {
        this.classes.sort((firstClass, secondClass) => {
            if (firstClass.range[1] < secondClass.range[0]) {
                return -1;
            } else if (firstClass.range[1] > secondClass.range[0]) {
                return 1;
            } else {
                return 0;
            }
        })
    }
    constructor() {
        this.sortMaps();
    }
    public getSubscoreLabel = (subscoreName: string) => {
        const subscore = this.subscoreTable.find(element => element.subscore === subscoreName);
        return subscore && subscore.label;
    }
    public isValidSubscoreValue = (subscoreName:string, value:string): boolean =>{
        if(!subscoreName || !value){
            return false;
        }
        const subscore = this.subscoreTable.find(element => element.subscore == subscoreName);
        if(!subscore){
            return false;
        }  
        const val = subscore.values.find(item => item.label === value);
        return !!val;
    }
    public getSubscoreColor = (subscoreName: string, currentValue) => {
        const subscore = this.subscoreTable.find(element => element.subscore === subscoreName);
        if(!subscore){
            return;
        }
        const val = subscore.values.find(value => value.label === currentValue);
        return val && val.color;
    }
    public getClass = (className: string) => {
        return this.classes.find(classItem => classItem.class === className);
    }
    public getClassColors = () => {
        const colors = [];
        for (const classItem of this.classes) {
            colors.push(classItem.color);
        }
        return colors;
    }
    public getClassLabel = (value: number) => {
        const colors = [];
        let label = "";
        for (const classItem of this.classes) {
            const range = classItem.range;
            if (value >= range[0] && value <= range[1]) {
                label = classItem.label;
            }
        }
        return label;
    }
    public getClassIntervals = () => {
        const range = [];
        for (const classItem of this.classes) {
            range.push(classItem.range);
        }
        return range;
    }
    public getSubscore = (subscoreName: string) => {
        if(!subscoreName){
            return;
        }
        return this.subscoreTable.find(subscore => subscore.subscore === subscoreName);
    }
    public getColorByClass = (className: string)=>{
        if(!className) return;
        const cgsClass = this.getClass(className);
        return cgsClass && cgsClass.color;
    }
}