import { DataSource } from '@n7-frontend/core';
export class ScoreDS extends DataSource {
  protected transform(data): any {
    if(!data || !data.find(element=> element.id === 'score')){
      return;
    }

    return data.find(element=> element.id === 'score');
  }
}
